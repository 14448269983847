import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import softStyleProps from "@/styles/softStyleProps";
import { useEffect, useState } from "react";
import { useCallListMetadata } from "@/hooks/useCallList";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { RootState } from "@/store/store";
import { useScripts } from "@/hooks/useScripts";
import { useCompanyUsers } from "@/hooks/useCompanyUsers";
import { CallHistoryFilter } from "@/features/CallHistory/CallHistoryCursor";
import { Checkbox } from "@mui/material";
import { sortCallNoteResults } from "@/models/CallHistory";

const initialFilterState: CallHistoryFilter = {
  startDate: dayjs().add(-7, "day"),
  endDate: dayjs(),
  nextCallStartDate: null,
  nextCallEndDate: null,
  minCallDuration: 0,
  uid: [],
  callResult: [],
  callListId: [],
  scriptId: [],
  keyword: "",
  phoneNumber: "",
};

const dateNow = dayjs();

const CallListFilter = ({
  onSubmit = () => {},
}: {
  onSubmit?: (filter: CallHistoryFilter) => void;
}) => {
  const callNoteResults = useSelector(
    (state: RootState) => state.company.config.callNoteResults
  );
  const users = useCompanyUsers();
  const callHistory = useSelector(
    (state: RootState) => state.call.filteredCallHistory
  );
  const callListMetadata = useCallListMetadata();
  const scripts = useScripts();

  const [filter, setFilter] = useState<CallHistoryFilter>(initialFilterState);

  // 最初の1回だけ自動でsubmit
  useEffect(() => {
    if (!Object.keys(callHistory).length) onSubmit(filter);
  });

  const onCallResultHandleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    console.log(value);
    if (value.includes("")) {
      setFilter({ ...filter, callResult: [] });
    } else {
      setFilter({ ...filter, callResult: value as string[] });
    }
  };

  const onCallListHandleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    if (value.includes("")) {
      setFilter({ ...filter, callListId: [] });
    } else {
      setFilter({ ...filter, callListId: value as string[] });
    }
  };

  const onScriptHandleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    if (value.includes("")) {
      setFilter({ ...filter, scriptId: [] });
    } else {
      setFilter({ ...filter, scriptId: value as string[] });
    }
  };

  const onUidHandleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    if (value.includes("")) {
      setFilter({ ...filter, uid: [] });
    } else {
      setFilter({ ...filter, uid: value as string[] });
    }
  };

  return (
    <Stack>
      <Stack gap={2} mt={3}>
        <Box display="flex" gap={4} maxWidth={1100}>
          <Stack gap={2}>
            <Box display="flex" gap={1}>
              <Box display="flex" alignItems="center" mr="auto">
                <Typography textAlign="left">コール日</Typography>
              </Box>
              <Box width={200}>
                <DatePicker
                  slotProps={{ textField: softStyleProps }}
                  format="YYYY-MM-DD"
                  value={filter.startDate}
                  onChange={(e) => setFilter({ ...filter, startDate: e })}
                  maxDate={filter.endDate}
                ></DatePicker>
              </Box>
              <Stack justifyContent="center">
                <Typography variant="h5">~</Typography>
              </Stack>
              <Box width={200}>
                <DatePicker
                  slotProps={{ textField: softStyleProps }}
                  format="YYYY-MM-DD"
                  value={filter.endDate}
                  onChange={(e) => setFilter({ ...filter, endDate: e })}
                  minDate={filter.startDate}
                  maxDate={dateNow}
                ></DatePicker>
              </Box>
            </Box>

            <Box display="flex" gap={1}>
              <Box display="flex" alignItems="center" mr="auto">
                <Typography textAlign="left">次回コール日</Typography>
              </Box>
              <Box width={200}>
                <DatePicker
                  slotProps={{
                    textField: softStyleProps,
                    field: { clearable: true },
                  }}
                  format="YYYY-MM-DD"
                  value={filter.nextCallStartDate}
                  onChange={(e) =>
                    setFilter({ ...filter, nextCallStartDate: e })
                  }
                  maxDate={filter.nextCallEndDate}
                ></DatePicker>
              </Box>
              <Stack justifyContent="center">
                <Typography variant="h5">~</Typography>
              </Stack>
              <Box width={200}>
                <DatePicker
                  slotProps={{
                    textField: softStyleProps,
                    field: { clearable: true },
                  }}
                  format="YYYY-MM-DD"
                  value={filter.nextCallEndDate}
                  onChange={(e) => setFilter({ ...filter, nextCallEndDate: e })}
                  minDate={filter.nextCallStartDate}
                ></DatePicker>
              </Box>
            </Box>
          </Stack>

          <Stack flexGrow={1} gap={2}>
            <TextField
              size="small"
              sx={{ ...softStyleProps.sx, width: "100%" }}
              label="キーワードで検索"
              value={filter.keyword}
              onChange={(e) =>
                setFilter({ ...filter, keyword: e.target.value })
              }
            ></TextField>

            <TextField
              size="small"
              sx={{ ...softStyleProps.sx, width: "100%" }}
              label="電話番号で検索"
              value={filter.phoneNumber}
              onChange={(e) =>
                setFilter({ ...filter, phoneNumber: e.target.value })
              }
            ></TextField>
          </Stack>
        </Box>

        <Box display="flex" gap={2} maxWidth={1100} mt={3}>
          <Grid container columns={12} spacing={2}>
            <Grid item xs={2}>
              <FormControl size="small" fullWidth>
                <InputLabel shrink>ユーザー</InputLabel>
                <Select
                  multiple
                  {...softStyleProps}
                  value={filter.uid.length === 0 ? [] : filter.uid}
                  onChange={onUidHandleChange}
                  renderValue={(selected) =>
                    (selected as string[]).length === 0 ||
                    (selected as string[]).includes("")
                      ? "全て"
                      : (selected as string[])
                          .map((value) => users[value]?.name || value)
                          .join(", ")
                  }
                  displayEmpty
                >
                  <MenuItem value="">全て</MenuItem>
                  {Object.values(users).map((user) => (
                    <MenuItem value={user.id} key={user.id}>
                      <Checkbox checked={filter.uid.includes(user.id)} />
                      {user.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={2}>
              <FormControl size="small" fullWidth>
                <InputLabel shrink>コール結果</InputLabel>
                <Select
                  multiple
                  {...softStyleProps}
                  value={
                    filter.callResult.length === 0 ? [] : filter.callResult
                  }
                  onChange={onCallResultHandleChange}
                  renderValue={(selected) =>
                    (selected as string[]).length === 0 ||
                    (selected as string[]).includes("")
                      ? "全て"
                      : (selected as string[])
                          .map((value) => callNoteResults[value]?.name || value)
                          .join(", ")
                  }
                  displayEmpty
                >
                  <MenuItem value="">全て</MenuItem>
                  {sortCallNoteResults(Object.values(callNoteResults)).map(
                    (result) => (
                      <MenuItem value={result.id} key={result.id}>
                        <Checkbox
                          checked={filter.callResult.includes(result.id)}
                        />
                        {result.name}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={2}>
              <FormControl size="small" fullWidth>
                <InputLabel shrink>コールリスト</InputLabel>
                <Select
                  multiple
                  {...softStyleProps}
                  value={
                    filter.callListId.length === 0 ? [] : filter.callListId
                  }
                  onChange={onCallListHandleChange}
                  renderValue={(selected) =>
                    (selected as string[]).length === 0 ||
                    (selected as string[]).includes("")
                      ? "全て"
                      : (selected as string[])
                          .map((value) => callListMetadata[value].name)
                          .join(", ")
                  }
                  displayEmpty
                >
                  <MenuItem value="">全て</MenuItem>
                  {Object.values(callListMetadata || {}).map((metadata) => (
                    <MenuItem value={metadata.id} key={metadata.id}>
                      <Checkbox
                        checked={filter.callListId.includes(metadata.id)}
                      />
                      {metadata.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={2}>
              <FormControl size="small" fullWidth>
                <InputLabel shrink>スクリプト</InputLabel>
                <Select
                  multiple
                  {...softStyleProps}
                  value={filter.scriptId.length === 0 ? [] : filter.scriptId}
                  onChange={onScriptHandleChange}
                  renderValue={(selected) =>
                    (selected as string[]).length === 0 ||
                    (selected as string[]).includes("")
                      ? "全て"
                      : (selected as string[])
                          .map((value) => scripts[value].name)
                          .join(", ")
                  }
                  displayEmpty
                >
                  <MenuItem value="">全て</MenuItem>
                  {Object.entries(scripts || {}).map(([id, script]) => (
                    <MenuItem value={id} key={id}>
                      <Checkbox checked={filter.scriptId.includes(id)} />
                      {script.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={2}>
              <FormControl size="small" fullWidth>
                <InputLabel>通話時間</InputLabel>
                <Select
                  {...softStyleProps}
                  value={filter.minCallDuration}
                  onChange={(e) =>
                    setFilter({
                      ...filter,
                      minCallDuration: Number(e.target.value),
                    })
                  }
                  displayEmpty
                >
                  <MenuItem value={0}>全て</MenuItem>
                  <MenuItem value={60}>1分~</MenuItem>
                  <MenuItem value={180}>3分~</MenuItem>
                  <MenuItem value={300}>5分~</MenuItem>
                  <MenuItem value={600}>10分~</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={2}>
              <Button variant="contained" onClick={() => onSubmit(filter)}>
                フィルターを適用
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </Stack>
  );
};

export default CallListFilter;
