import { components } from "./telai-backend/schema";

const callResultColors: { [key: string]: string } = {
  TOSSUP_APPOINTMENT: "#2f78e4",
  TOSSUP_NURTURING_A: "#916fe1",
  TOSSUP_NURTURING_B: "#916fe1",
  TOSSUP_NURTURING_C: "#916fe1",
  TOSSUP_ABSENT: "#ce61ce",
  AI_NURTURING: "#f956b0",
  FINISHED_ON_AI: "#ff578b",
  IMPOSSIBLE: "#ff6a63",
  DENIED: "#ef5350",
  DENIED_ON_AI: "#ef5350",
  NO_RESPONSE: "#bebebe",
  AUTOMATED: "#e0e0e0",
  UNREACHABLE: "#e0e0e0",
  SIMULTANEOUS_TOSSUP: "#bebebe",
  TOSSUP_NOT_REGISTERED: "#bebebe",
};
type CallDirection = "OUTGOING" | "INCOMING";

type CallHistoryRecord = {
  id: string;
  companyName: string;
  createdAt: string;
  updatedAt: string;
  calledAt: string;
  callDirection: CallDirection;
  callDuration: number;
  callMemo: string;
  result: string;
  nextCallDate?: Date;
  prevCallSid?: string; // 再架電のコール履歴場合、ここに再架電されたナーチャリングコールのSIDが入る
  phoneNumber: string;
  audioPath: string;
  transcriptPath: string;
  operatorId: string;
  scriptId: string;
  callListId: string;
  toContactId: string;
  fromContactId: string;
  callListIndex: number;
  manualCall?: boolean;
  redialAttempts: number;
  phoneCallId: string;
  callTags: string[];
};

type CallHistoryTranscript = {
  content: string;
  speaker: string;
  timestamp: number;
};

function sortCallNoteResults(
  results: components["schemas"]["CallNoteResult"][]
): components["schemas"]["CallNoteResult"][] {
  return results.sort((a, b) => {
    if (a.type === "TOSSUP" && b.type !== "TOSSUP") {
      return -1;
    }
    if (a.type !== "TOSSUP" && b.type === "TOSSUP") {
      return 1;
    }

    if (a.type === "AI" && b.type !== "AI") {
      return -1;
    }
    if (a.type !== "AI" && b.type === "AI") {
      return 1;
    }

    if (a.type === "GENERAL" && b.type !== "GENERAL") {
      return -1;
    }
    if (a.type !== "GENERAL" && b.type === "GENERAL") {
      return 1;
    }

    if (a.type === "MANUAL_OUTBOUND" && b.type !== "MANUAL_OUTBOUND") {
      return -1;
    }
    if (a.type !== "MANUAL_OUTBOUND" && b.type === "MANUAL_OUTBOUND") {
      return 1;
    }

    if (a.type === "MANUAL_INBOUND" && b.type !== "MANUAL_INBOUND") {
      return -1;
    }
    if (a.type !== "MANUAL_INBOUND" && b.type === "MANUAL_INBOUND") {
      return 1;
    }

    const aScore =
      (a.connected && 1) + (a.handedOff && 2) + (a.achievedAppointment && 4);
    const bScore =
      (b.connected && 1) + (b.handedOff && 2) + (b.achievedAppointment && 4);

    if (aScore > bScore) {
      return -1;
    }
    if (aScore < bScore) {
      return 1;
    }

    if (a.name < b.name) {
      return -1;
    }

    return 1;
  });
}

export { callResultColors, sortCallNoteResults };
export type { CallDirection, CallHistoryRecord, CallHistoryTranscript };
