// import { useCompanyUsers } from "@/hooks/useCompanyUsers";
import { RedialContact } from "@/models/Call";
import { request } from "@/models/telai-backend/client";
import { setLoadingBackdrop, setSnackbar } from "@/store/commonSlice";
import { AppDispatch, RootState } from "@/store/store";
import {
  CallOutlined,
  CheckOutlined,
  PhoneOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridValueFormatterParams,
  jaJP,
} from "@mui/x-data-grid";
import React, { useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  setManualRedialPage,
  setAiTagOnlyPage,
} from "@/store/redialCallStateSlice";

const RedialManual = React.memo(
  ({
    manualRedialCalls,
    reFetchNurturingCall,
    changePageOnManualRedial,
    manualRedialCallsOnlyTags,
    changePageOnManualRedialOnlyTags,
  }: {
    manualRedialCalls: RedialContact;
    reFetchNurturingCall: (limit: number, offset: number) => void;
    changePageOnManualRedial: (
      limit: number,
      offset: number,
      sortField: string,
      sort: "asc" | "desc"
    ) => void;
    manualRedialCallsOnlyTags: RedialContact;
    changePageOnManualRedialOnlyTags: (
      limit: number,
      offset: number,
      sortField: string,
      sort: "asc" | "desc"
    ) => void;
  }) => {
    const callNoteResults = useSelector(
      (state: RootState) => state.company.config.callNoteResults
    );
    // const users = useCompanyUsers();
    const { tenantId } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();

    // 手動再コール
    const [manualRedialDialog, setManualRedialDialog] = useState(false);
    const [manualRedialDialogOnlyTags, setManualRedialDialogOnlyTags] =
      useState(false);
    const [manualRedialSelection, setManualRedialSelection] = useState([]);
    const [manualRedialSelectionOnlyTags, setManualRedialSelectionOnlyTags] =
      useState([]);
    const [pageModel, setPageModel] = useState({
      page: 0,
      pageSize: 10,
      sortField: "" as string,
      sort : "desc" as "asc" | "desc", 
    });

    //ページネーションのState
    const updateManualRedialPage = (offset: number, limit: number) => {
      dispatch(setManualRedialPage({ offset, limit }));
    };
    const updateAiTagOnlyPage = (offset: number, limit: number) => {
      dispatch(setAiTagOnlyPage({ offset, limit }));
    };
    const RedialPageState = useSelector(
      (state: RootState) => state.redial.redialPage
    );

    const manualRedialColumns: GridColDef[] = [
      { field: "name", headerName: "会社名", width: 150 },
      // { field: "phoneNumber", headerName: "電話番号", width: 100 },
      {
        field: "latestCallNoteResult",
        headerName: "コール結果",
        width: 160,
        valueFormatter: (params: GridValueFormatterParams<string>) =>
          params.value ? callNoteResults[params.value]?.name : params.value,
      },
      {
        field: "latestCallCalledAt",
        headerName: "通話日付",
        width: 120,
        valueFormatter: (params: GridValueFormatterParams<Date>) =>
          params.value ? new Date(params.value).toLocaleDateString() : "",
      },
      {
        field: "latestCallNextCallScheduledAt",
        headerName: "次回架電予定",
        width: 120,
        valueFormatter: (params: GridValueFormatterParams<Date>) =>
          params.value ? new Date(params.value).toLocaleDateString() : "未設定",
      },
      // { field: "operatorId", headerName: "担当者", width: 120 },
      { field: "latestCallTags", headerName: "推奨コール時間", width: 120 },
    ] as const;

    const manualRedialColumnsOnlyTags: GridColDef[] = [
      { field: "name", headerName: "会社名", width: 150 },
      // { field: "phoneNumber", headerName: "電話番号", width: 100 },
      {
        field: "latestCallNoteResult",
        headerName: "コール結果",
        width: 160,
        valueFormatter: (params: GridValueFormatterParams<string>) =>
          params.value ? callNoteResults[params.value]?.name : params.value,
      },
      {
        field: "latestCallCalledAt",
        headerName: "通話日付",
        width: 120,
        valueFormatter: (params: GridValueFormatterParams<Date>) =>
          params.value ? new Date(params.value).toLocaleDateString() : "",
      },
      {
        field: "latestCallNextCallScheduledAt",
        headerName: "次回架電予定",
        width: 120,
        valueFormatter: (params: GridValueFormatterParams<Date>) =>
          params.value ? new Date(params.value).toLocaleDateString() : "未設定",
      },
      // { field: "operatorId", headerName: "担当者", width: 120 },
      { field: "latestCallTags", headerName: "推奨コール時間", width: 120 },
    ] as const;

    // 選択されたコール履歴を再コール済みとしてマークする
    const handleRegisterNextCalls = useCallback(async () => {
      console.log(
        "manualRedialSelection",
        manualRedialSelection[0],
        manualRedialSelection
      );

      setManualRedialDialog(false);
      dispatch(
        setLoadingBackdrop({ key: "handleRegisterNextCalls", state: true })
      );

      try {
        const callIds = manualRedialSelection.map((call) => {
          console.log("nurturingCall", manualRedialSelection);
          return manualRedialCalls.contacts.find((row) => row.id === call)
            .latestCallId;
        });

        const promises = callIds.map(async (callId) => {
          await request({
            path: "/calls/{callId}/note",
            httpMethod: "put",
            params: {
              paths: { callId: callId },
              body: {
                nextCallCompleted: true,
              },
            },
          }).then(async (res) => {
            console.log("res", res);
            // 再コールリストを再取得
          });
        });

        // 全ての非同期処理が完了するのを待つ
        await Promise.all(promises);

        // 再コールリストを再取得
        await reFetchNurturingCall(
          pageModel.pageSize,
          pageModel.page * pageModel.pageSize
        );
        await setManualRedialSelection([]);

        await dispatch(
          setSnackbar({
            text: "正常に登録しました。",
            open: true,
            severity: "success",
          })
        );
      } catch (e) {
        console.error(e);
        dispatch(
          setSnackbar({
            text: "登録処理中にエラーが発生しました。",
            open: true,
            severity: "error",
          })
        );
      } finally {
        dispatch(
          setLoadingBackdrop({ key: "handleRegisterNextCalls", state: false })
        );
      }
    }, [
      manualRedialSelection,
      dispatch,
      reFetchNurturingCall,
      pageModel.pageSize,
      pageModel.page,
      manualRedialCalls.contacts,
    ]);

    // 選択されたコール履歴を再コール済みとしてマークする
    const handleRegisterNextCallsOnlyTags = useCallback(async () => {
      console.log(
        "manualRedialSelectionOnlyTags",
        manualRedialSelectionOnlyTags[0],
        manualRedialSelectionOnlyTags
      );

      setManualRedialDialogOnlyTags(false);
      await dispatch(
        setLoadingBackdrop({ key: "handleRegisterNextCalls", state: true })
      );
      try {
        const callIds = manualRedialSelectionOnlyTags.map((call) => {
          console.log("nurturingCall", manualRedialSelectionOnlyTags);
          return manualRedialCallsOnlyTags.contacts.find(
            (row) => row.id === call
          ).latestCallId;
        });

        const promises = callIds.map(async (callId) => {
          await request({
            path: "/calls/{callId}/note",
            httpMethod: "put",
            params: {
              paths: { callId: callId },
              body: {
                nextCallCompleted: true,
              },
            },
          }).then(async (res) => {
            console.log("res", res);
            // 再コールリストを再取得
            // 1秒待ってから再PUT
          });
          await new Promise((resolve) => setTimeout(resolve, 1000));
        });

        // 全ての非同期処理が完了するのを待つ
        await Promise.all(promises);

        await new Promise((resolve) => setTimeout(resolve, 1000));
        await reFetchNurturingCall(
          pageModel.pageSize,
          pageModel.page * pageModel.pageSize
        );
        await setManualRedialSelectionOnlyTags([]);

        await dispatch(
          setSnackbar({
            text: "正常に登録しました。",
            open: true,
            severity: "success",
          })
        );
      } catch (e) {
        console.error(e);
        dispatch(
          setSnackbar({
            text: "登録処理中にエラーが発生しました。",
            open: true,
            severity: "error",
          })
        );
      } finally {
        await dispatch(
          setLoadingBackdrop({ key: "handleRegisterNextCalls", state: false })
        );
      }
    }, [
      manualRedialSelectionOnlyTags,
      dispatch,
      reFetchNurturingCall,
      pageModel.pageSize,
      pageModel.page,
      manualRedialCallsOnlyTags.contacts,
    ]);

    console.log("manualRedialCalls", manualRedialCalls);

    const manualRedialRows = useMemo(() => {
      // latestCallNoteNextCallCompletedがtrueの場合は除外
      console.log("manualRedialCalls", manualRedialCalls);
      return manualRedialCalls.contacts;
    }, [manualRedialCalls.contacts]);

    const manualRedialRowsOnlyTags = useMemo(() => {
      // latestCallNoteNextCallCompletedがtrueの場合は除外
      console.log("manualRedialCallsOnlyTags", manualRedialCallsOnlyTags);
      return manualRedialCallsOnlyTags.contacts;
    }, [manualRedialCallsOnlyTags.contacts]);

    const manualCallNavigateUrl = useMemo(async () => {
      const phoneNumber = manualRedialRows.find(
        (row) => row.id === manualRedialSelection[0]
      )?.phone_number;
      // const ids = manualRedialRows.find((row) => row.id === manualRedialSelection[0])?.prevCallSids.join(",")
      const ids = manualRedialRows.find(
        (row) => row.id === manualRedialSelection[0]
      )?.id;
      const callId = manualRedialRows.find(
        (row) => row.id === manualRedialSelection[0]
      )?.latestCallId;
      console.log("manualCallNavigateUrl", phoneNumber, ids, callId);
      if (callId) {
        // コール詳細を取得
        const callDetail = await request({
          path: "/calls/{callId}",
          httpMethod: "get",
          params: {
            paths: {
              callId: callId,
            },
          },
        });
        console.log("callDetail", callDetail);
        if (!phoneNumber) return `/${tenantId}/manualcall`;
        return `/${tenantId}/manualcall?p=${phoneNumber.replace(/^\+81/, "0")}${
          ids ? `&ids=${ids}` : ""
        }${
          callDetail.data.toContactId
            ? `&contactId=${callDetail.data.toContactId}`
            : ""
        }`;
      }
      if (!phoneNumber) return `/${tenantId}/manualcall`;
      return `/${tenantId}/manualcall?p=${phoneNumber.replace(/^\+81/, "0")}${
        ids ? `&ids=${ids}` : ""
      }`;
    }, [manualRedialRows, manualRedialSelection, tenantId]);

    const manualCallNavigateUrlOnlyTags = useMemo(async () => {
      console.log(
        "manualRedialRowsOnlyTags",
        manualRedialRowsOnlyTags,
        manualRedialSelectionOnlyTags
      );
      const phoneNumber = manualRedialRowsOnlyTags.find(
        (row) => row.id === manualRedialSelectionOnlyTags[0]
      )?.phone_number;
      // const ids = manualRedialRows.find((row) => row.id === manualRedialSelection[0])?.prevCallSids.join(",")
      const ids = manualRedialRowsOnlyTags.find(
        (row) => row.id === manualRedialSelectionOnlyTags[0]
      )?.id;
      const callId = manualRedialRowsOnlyTags.find(
        (row) => row.id === manualRedialSelectionOnlyTags[0]
      )?.latestCallId;
      console.log("manualCallNavigateUrl", phoneNumber, ids, callId);
      if (callId) {
        // コール詳細を取得
        const callDetail = await request({
          path: "/calls/{callId}",
          httpMethod: "get",
          params: {
            paths: {
              callId: callId,
            },
          },
        });
        console.log("callDetail", callDetail);
        if (!phoneNumber) return `/${tenantId}/manualcall`;
        return `/${tenantId}/manualcall?p=${phoneNumber.replace(/^\+81/, "0")}${
          ids ? `&ids=${ids}` : ""
        }${
          callDetail.data.toContactId
            ? `&contactId=${callDetail.data.toContactId}`
            : ""
        }`;
      }
      if (!phoneNumber) return `/${tenantId}/manualcall`;
      return `/${tenantId}/manualcall?p=${phoneNumber.replace(/^\+81/, "0")}${
        ids ? `&ids=${ids}` : ""
      }`;
    }, [manualRedialRowsOnlyTags, manualRedialSelectionOnlyTags, tenantId]);

    return (
      <Box sx={{ mx: "auto", position: "relative", display: "inline-block" }}>
        <Stack direction="row" mb={2}>
          <Stack alignItems="center" direction="row">
            <CallOutlined sx={{ mr: 1 }}></CallOutlined>
            <Typography textAlign="left" fontWeight="600" fontSize="1.1rem">
              手動再コール
            </Typography>
          </Stack>
        </Stack>
        <Stack alignContent="center" flexWrap="wrap" gap={2}>
          <Paper sx={{ overflow: "hidden" }}>
            <DataGrid
              columns={manualRedialColumns}
              rows={manualRedialRows}
              rowCount={manualRedialCalls.total}
              paginationMode="server"
              initialState={{
                pagination: {
                  paginationModel: {
                    page:
                      RedialPageState.manualRedialCallOffset /
                      RedialPageState.manualRedialCallLimit,
                    pageSize: RedialPageState.manualRedialCallLimit,
                  },
                },
              }}
              sx={{
                scrollbarColor: "#ddd #f6f6f6",
                scrollbarWidth: "thin",
                cursor: "pointer",
              }}
              pageSizeOptions={[10, 20, 50, 100]}
              rowHeight={36}
              checkboxSelection
              rowSelectionModel={manualRedialSelection}
              onRowSelectionModelChange={(model) => {
                setManualRedialSelection(model);
              }}
              localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
              onPaginationModelChange={(model) => {
                console.log("paginationModel", pageModel);
                updateManualRedialPage(
                  model.page * model.pageSize,
                  model.pageSize
                );
                setPageModel((prev) => ({
                  ...prev,
                  pageSize: model.pageSize,
                  page: model.page,
                }));
                changePageOnManualRedial(
                  model.pageSize,
                  model.page * model.pageSize,
                  pageModel.sortField,
                  pageModel.sort
                );
              }}
              onSortModelChange={(model) => {
                console.log("sortModel", model);
                if (model.length > 0) { 
                  console.log("sortModel", pageModel);
                  setPageModel((prev) => ({
                    ...prev,
                    sortField: model[0].field ?? "",
                    sort: model[0].sort ?? "desc",
                  }));
                  changePageOnManualRedial(pageModel.pageSize, pageModel.page * pageModel.pageSize, model[0].field, model[0].sort ?? "desc");
                }else {
                  // ソートが解除された場合
                  console.log("sortModel", pageModel);
                  setPageModel((prev) => ({
                    ...prev,
                    sortField: "",
                    sort: "desc",
                  }));
                  changePageOnManualRedial(pageModel.pageSize, pageModel.page * pageModel.pageSize, null, "desc");
                }
              }}
              onRowClick={(params) => {
                console.log("onRowClick", params);
                navigate(`/${tenantId}/history/${params.row.latestCallId}`);
              }}
            ></DataGrid>
          </Paper>
          <Stack direction="row" justifyContent="end" gap={2}>
            <Button
              color="secondary"
              variant="outlined"
              startIcon={<PhoneOutlined></PhoneOutlined>}
              disabled={manualRedialSelection.length !== 1}
              onClick={async () => navigate(await manualCallNavigateUrl)}
            >
              手動コールページへ移動
            </Button>

            <Button
              variant="contained"
              startIcon={<CheckOutlined></CheckOutlined>}
              disabled={manualRedialSelection.length < 1}
              onClick={() => setManualRedialDialog(true)}
            >
              {manualRedialSelection.length}件を再コール済みとして登録
            </Button>
          </Stack>
        </Stack>
        {/**
         * -----------------------------------------------------
         * 手動再コール(コールタグのみ)
         * -----------------------------------------------------
         */}
        <Stack direction="row" mb={2} mt={2}>
          <Stack alignItems="center" direction="row">
            <CallOutlined sx={{ mr: 1 }}></CallOutlined>
            <Typography textAlign="left" fontWeight="600" fontSize="1.1rem">
              手動再コール(コールタグのみ)
            </Typography>
          </Stack>
        </Stack>
        <Stack alignContent="center" flexWrap="wrap" gap={2}>
          <Paper sx={{ overflow: "hidden" }}>
            <DataGrid
              columns={manualRedialColumnsOnlyTags}
              rows={manualRedialRowsOnlyTags}
              rowCount={manualRedialCallsOnlyTags.total}
              paginationMode="server"
              initialState={{
                pagination: {
                  paginationModel: {
                    page:
                      RedialPageState.manualRedialCallOnlyTagsOffset /
                      RedialPageState.manualRedialCallOnlyTagsLimit,
                    pageSize: RedialPageState.manualRedialCallLimit,
                  },
                },
              }}
              sx={{
                scrollbarColor: "#ddd #f6f6f6",
                scrollbarWidth: "thin",
                cursor: "pointer",
              }}
              pageSizeOptions={[10, 20, 50, 100]}
              rowHeight={36}
              checkboxSelection
              rowSelectionModel={manualRedialSelectionOnlyTags}
              onRowSelectionModelChange={(model) => {
                setManualRedialSelectionOnlyTags(model);
              }}
              localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
              onPaginationModelChange={(model) => {
                console.log("paginationModel", pageModel);
                updateAiTagOnlyPage(
                  model.page * model.pageSize,
                  model.pageSize
                );
                setPageModel((prev) => ({
                  ...prev,
                  pageSize: model.pageSize,
                  page: model.page,
                }));
                changePageOnManualRedialOnlyTags(
                  model.pageSize,
                  model.page * model.pageSize,
                  pageModel.sortField,
                  pageModel.sort
                );
              }}
              onSortModelChange={(model) => {
                console.log("sortModel", model);
                if(model.length > 0) {  
                  console.log("sortModel", pageModel);
                  setPageModel((prev) => ({
                    ...prev,
                    sortField: model[0].field ?? "",
                    sort: model[0].sort ?? "desc",
                  }));
                  changePageOnManualRedialOnlyTags(pageModel.pageSize, pageModel.page * pageModel.pageSize, model[0].field ?? "", model[0].sort ?? "desc");
                }else{
                  // ソートが解除された場合
                  console.log("sortModel", pageModel);
                  setPageModel((prev) => ({
                    ...prev,
                    sortField: "",
                    sort: "desc",
                  }));
                  changePageOnManualRedialOnlyTags(pageModel.pageSize, pageModel.page * pageModel.pageSize, null, "desc");
                }
              }}
              onRowClick={(params) => {
                console.log("onRowClick", params);
                navigate(`/${tenantId}/history/${params.row.latestCallId}`);
              }}
            ></DataGrid>
          </Paper>
          <Stack direction="row" justifyContent="end" gap={2}>
            <Button
              color="secondary"
              variant="outlined"
              startIcon={<PhoneOutlined></PhoneOutlined>}
              disabled={manualRedialSelectionOnlyTags.length !== 1}
              onClick={async () =>
                navigate(await manualCallNavigateUrlOnlyTags)
              }
            >
              手動コールページへ移動
            </Button>

            <Button
              variant="contained"
              startIcon={<CheckOutlined></CheckOutlined>}
              disabled={manualRedialSelectionOnlyTags.length < 1}
              onClick={() => setManualRedialDialogOnlyTags(true)}
            >
              {manualRedialSelectionOnlyTags.length}件を再コール済みとして登録
            </Button>
          </Stack>
        </Stack>

        <Dialog open={manualRedialDialog}>
          <DialogTitle display="flex" gap={1} alignItems="center">
            <CheckOutlined></CheckOutlined>
            再コール登録
          </DialogTitle>
          <DialogContent>
            <Typography>
              {manualRedialSelection.length}
              件の項目を再コール済みとして登録しますか？
            </Typography>
            <Typography>
              再コール済みとして登録された項目は再コールリストから除外されます。
            </Typography>
          </DialogContent>
          <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => setManualRedialDialogOnlyTags(false)}
            >
              キャンセル
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => handleRegisterNextCalls()}
            >
              登録
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={manualRedialDialogOnlyTags}>
          <DialogTitle display="flex" gap={1} alignItems="center">
            <CheckOutlined></CheckOutlined>
            再コール登録
          </DialogTitle>
          <DialogContent>
            <Typography>
              {manualRedialSelectionOnlyTags.length}
              件の項目を再コール済みとして登録しますか？
            </Typography>
            <Typography>
              再コール済みとして登録された項目は再コールリストから除外されます。
            </Typography>
          </DialogContent>
          <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => setManualRedialDialogOnlyTags(false)}
            >
              キャンセル
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => handleRegisterNextCallsOnlyTags()}
            >
              登録
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  }
);

export default RedialManual;
