import RelativeBackdrop from "@/components/RelativeBackdrop";
import CallProcessPlaceHolder from "@/features/CallProcess/CallProcessPlaceHolder";
import CallStatsLineChart from "@/features/CallStats/CallStatsLineChart";
import CallStatsPieChart from "@/features/CallStats/CallStatsPieChart";
import CallStatsPieChartLegend from "@/features/CallStats/CallStatsPieChartLegend";
import { CallStatsFilter } from "@/google/callStats";
import { useCallProcesses } from "@/hooks/useCallProcesses";
import { useCallStatsByInterval } from "@/hooks/useCallStatsByInterval";
import { useCompanyConfig } from "@/hooks/useCompanyConfig";
import { CallBatch } from "@/models/CallBatch";
import { setCompanyConfig } from "@/store/companySlice";
import { RootState } from "@/store/store";
import {
  Box,
  LinearProgress,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { PieChart } from "@mui/x-charts/PieChart";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import CallProcessCard from "../features/CallProcess/CallProcessCard";
import CallProcessLogFilter from "../features/CallProcess/CallProcessLogFilter";
import CallResultSummaryLarge from "../features/CallProcess/CallResultSummaryLarge";
import Carousel from "../features/carousel/CarouselRepeat";
import { sortCallNoteResults } from "@/models/CallHistory";

type CallStatsSummary = { [key: string]: number };

const Home = () => {
  const callNoteResults = useSelector(
    (state: RootState) => state.company.config.callNoteResults
  );
  const uid = useSelector((state: RootState) => state.user.loggedInUser.id);
  const companyId = useSelector(
    (state: RootState) => state.user.loggedInUser.tenantId
  );
  const user = useSelector((state: RootState) => state.user.loggedInUser);
  const callProcesses = useCallProcesses();
  const settings = useCompanyConfig();

  //setting fetch
  useEffect(() => {
    setCompanyConfig(settings);
  }, [settings]);

  const shownCallProcess: Record<string, CallBatch> = useMemo(
    () =>
      Object.entries(callProcesses)
        .filter(([, process]) =>
          ["INITIALIZING", "RUNNING", "ABORTING", "IN_PROGRESS"].includes(
            process.status
          )
        )
        .reduce((prev, [docId, process]) => {
          prev[docId] = process;
          return prev;
        }, {}),
    [callProcesses]
  );

  const CallProcessesSorted = useMemo(() => {
    // 自分が割り当てられたプロセスを先頭に持ってきたオブジェクト
    if (!shownCallProcess) return {};

    const assignedProcessId = Object.keys(shownCallProcess).find((docId) =>
      user.assignedCallBatchIds.includes(docId)
    );
    if (assignedProcessId) {
      const others = { ...shownCallProcess };
      delete others[assignedProcessId];
      return {
        [assignedProcessId]: shownCallProcess[assignedProcessId],
        ...others,
      };
    }
    return shownCallProcess;
  }, [shownCallProcess, uid]);
  const [filter, setFilter] = useState<CallStatsFilter>({
    period: "TODAY",
    showingCallResults: Object.values(callNoteResults)
      .filter((result) => ["TOSSUP", "AI", "GENERAL"].includes(result.type))
      .map((result) => result.id),
  });
  const [statsSum, setStatsSum] = useState<CallStatsSummary>({});
  const [stats, fetching] = useCallStatsByInterval(filter);

  // 初回読み込み時に表示する項目を設定
  useEffect(() => {
    setFilter((prev) => ({
      ...prev,
      showingCallResults: Object.values(callNoteResults)
        .filter(
          (result) =>
            (["TOSSUP", "AI", "GENERAL"].includes(result.type) ||
              result.achievedAppointment) &&
            ["NEVER_CALLED", "ERROR"].includes(result.id) === false
        )
        .map((result) => result.id),
    }));
  }, [callNoteResults]);

  const { answerCount, tossUpCount, appointmentCount } = useMemo(() => {
    if (!statsSum)
      return { answerCount: 0, tossUpCount: 0, appointmentCount: 0 };
    return {
      answerCount: Object.values(callNoteResults)
        .filter(
          (result) =>
            ["TOSSUP", "AI", "GENERAL"].includes(result.type) &&
            result.connected
        )
        .reduce((acc, result) => acc + (statsSum[result.id] ?? 0), 0),
      tossUpCount: Object.values(callNoteResults)
        .filter(
          (result) =>
            ["TOSSUP", "AI", "GENERAL"].includes(result.type) &&
            result.handedOff
        )
        .reduce((acc, result) => acc + (statsSum[result.id] ?? 0), 0),
      appointmentCount: Object.values(callNoteResults)
        .filter((result) => result.achievedAppointment)
        .reduce((acc, result) => acc + (statsSum[result.id] ?? 0), 0),
    };
  }, [callNoteResults, statsSum]);

  const pieChartData = useMemo(() => {
    const sum: CallStatsSummary = {};
    // 初期化として 0 を入れる
    sortCallNoteResults(
      Object.values(callNoteResults).filter(
        (result) =>
          ["NEVER_CALLED", "ERROR"].includes(result.id) === false &&
          (["TOSSUP", "AI", "GENERAL"].includes(result.type) ||
            result.achievedAppointment)
      )
    ).forEach((result) => (sum[result.id] = 0));
    console.log(stats);
    Object.values(stats).forEach((data) => {
      // 表示している項目
      sortCallNoteResults(
        Object.values(callNoteResults).filter(
          (result) =>
            ["NEVER_CALLED", "ERROR"].includes(result.id) === false &&
            (["TOSSUP", "AI", "GENERAL"].includes(result.type) ||
              result.achievedAppointment)
        )
      ).forEach((result) => (sum[result.id] += data[result.id] ?? 0));
    });

    setStatsSum(sum);
    return Object.entries(sum).map(([key, value]) => ({
      id: key,
      value,
      label: callNoteResults[key]?.name || key,
    }));
  }, [callNoteResults, stats]);

  // 正規料金課金コール数=総コール数-不通
  const connectionRingData = useMemo(() => {
    return [
      {
        id: "CONNECTION",
        label: "接続",
        value: Object.values(callNoteResults)
          .filter((result) => result.connected)
          .reduce((acc, result) => acc + (statsSum[result.id] ?? 0), 0),
      },
      {
        id: "NO_RESPONSE",
        label: "不通",
        value: Object.values(callNoteResults)
          .filter((result) => ["UNREACHABLE", "AUTOMATED"].includes(result.id))
          .reduce((acc, result) => acc + (statsSum[result.id] ?? 0), 0),
      },
    ];
  }, [callNoteResults, statsSum]);

  const tossUpRingData = useMemo(() => {
    return [
      {
        id: "TOSSUP",
        label: "トスアップ",
        value: Object.values(callNoteResults)
          .filter((result) => result.handedOff)
          .reduce((acc, result) => acc + (statsSum[result.id] ?? 0), 0),
      },
      {
        id: "NO_RESPONSE",
        label: "AI対応",
        value: Object.values(callNoteResults)
          .filter((result) => result.type === "AI")
          .reduce((acc, result) => acc + (statsSum[result.id] ?? 0), 0),
      },
    ];
  }, [callNoteResults, statsSum]);

  const handleFilterApply = (newFilter: CallStatsFilter) => {
    setFilter({
      ...newFilter,
    });
  };

  useEffect(() => {
    setFilter({ ...filter });
  }, [companyId]);

  return (
    <Stack
      sx={{ width: "100%" }}
      position="relative"
      width="100%"
      bgcolor="#fbfbfb"
    >
      <Box
        bgcolor="#E1E4E8"
        width="100%"
        sx={{
          display: "flex",
          flexDirection: "row",
          alighItems: "center",
          alighContent: "center",
          justifyContent: "center",
          pt: 3,
        }}
      >
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Box sx={{ overflow: "visible" }}>
            <Carousel
              cards={
                Object.entries(CallProcessesSorted).length
                  ? Object.entries(CallProcessesSorted).map((process, i) => (
                      <CallProcessCard callProcess={process[1]} key={i} />
                    ))
                  : [<CallProcessPlaceHolder key={-1} />]
              }
              cardsPerPage={2}
              spacing={3}
            />
          </Box>
        </Stack>
      </Box>

      <Stack
        direction="row"
        justifyContent="center"
        pt={4}
        pb={8}
        sx={{ px: 8 }}
        gap={4}
        maxWidth={1200}
        mx="auto"
      >
        <Stack flexGrow={1} gap={4} width={760}>
          <Box sx={{ position: "relative" }}>
            {fetching ? (
              <Skeleton
                variant="rectangular"
                sx={{ width: "100%", height: 350 }}
              ></Skeleton>
            ) : (
              <Stack direction="row" maxWidth={760} gap={2}>
                <Paper sx={{ width: "100%" }}>
                  <CallStatsLineChart
                    xAxis={Object.keys(stats).map((key) => {
                      const [year, month, day] = key.split("-");
                      const formattedMonth = String(
                        parseInt(month, 10)
                      ).padStart(2, "0");
                      const formattedDay = String(parseInt(day, 10)).padStart(
                        2,
                        "0"
                      );
                      if (filter.period === "THIS_YEAR") {
                        return `${year}/${formattedMonth}`;
                      }
                      return `${year}/${formattedMonth}/${formattedDay}`;
                    })}
                    data={Object.values(stats)}
                  ></CallStatsLineChart>
                </Paper>
                <Stack justifyContent="center" gap={2}>
                  <Paper>
                    <Stack gap={1} p={2}>
                      <Box
                        px={2}
                        py={1}
                        mx={-2}
                        mt={-2}
                        mb={1}
                        bgcolor="#f3f3f3"
                      >
                        <Typography fontSize="0.9rem">接続率</Typography>
                      </Box>

                      <Box position="relative">
                        <PieChart
                          height={80}
                          width={80}
                          colors={["rgb(2, 178, 175)", "#cccccc"]}
                          series={[
                            {
                              data: connectionRingData,
                              innerRadius: 30,
                              outerRadius: 40,
                              paddingAngle: 5,
                              cornerRadius: 5,
                            },
                          ]}
                          slots={{ legend: () => <></> }}
                          margin={{ left: 0, right: 0 }}
                        />
                        <Typography
                          position="absolute"
                          sx={{
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                          }}
                        >
                          {(
                            (connectionRingData[0].value /
                              (connectionRingData[0].value +
                                connectionRingData[1].value)) *
                            100
                          ).toFixed(0)}
                          %
                        </Typography>
                      </Box>
                      <Typography fontSize="0.85rem" sx={{ mt: -1 }}>
                        {connectionRingData[0].value} /{" "}
                        {connectionRingData[0].value +
                          connectionRingData[1].value}
                      </Typography>
                    </Stack>
                  </Paper>

                  <Paper>
                    <Stack gap={1} p={2}>
                      <Box
                        px={1}
                        py={1}
                        mx={-2}
                        mt={-2}
                        mb={1}
                        bgcolor="#f3f3f3"
                      >
                        <Typography fontSize="0.9rem">トスアップ率</Typography>
                      </Box>
                      <Box position="relative">
                        <PieChart
                          height={80}
                          width={80}
                          colors={["rgb(46, 150, 255)", "#cccccc"]}
                          series={[
                            {
                              data: tossUpRingData,
                              innerRadius: 30,
                              outerRadius: 40,
                              paddingAngle: 5,
                              cornerRadius: 5,
                            },
                          ]}
                          slots={{ legend: () => <></> }}
                          margin={{ left: 0, right: 0 }}
                        />
                        <Typography
                          position="absolute"
                          sx={{
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                          }}
                        >
                          {(
                            (tossUpRingData[0].value /
                              (tossUpRingData[1].value +
                                tossUpRingData[0].value)) *
                            100
                          ).toFixed(0)}
                          %
                        </Typography>
                      </Box>
                      <Typography fontSize="0.85rem" sx={{ mt: -1 }}>
                        {tossUpRingData[0].value} /{" "}
                        {tossUpRingData[1].value + tossUpRingData[0].value}
                      </Typography>
                    </Stack>
                  </Paper>
                </Stack>
              </Stack>
            )}

            <RelativeBackdrop open={fetching}>
              <LinearProgress
                sx={{ position: "absolute", top: 0, width: "100%" }}
              ></LinearProgress>
            </RelativeBackdrop>
          </Box>

          <Stack sx={{ height: "100%", position: "relative" }}>
            <Stack direction="row" gap={2}>
              <Paper sx={{ p: 2 }}>
                <Stack gap={1}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    gap={0.5}
                    px={2}
                    py={1}
                    bgcolor="#f3f3f3"
                    mt={-2}
                    mx={-2}
                  >
                    <Typography>コール結果割合</Typography>
                  </Stack>
                  <CallStatsPieChart
                    data={pieChartData.filter((data) =>
                      filter.showingCallResults.includes(data.id)
                    )}
                  />
                </Stack>
              </Paper>

              <Paper sx={{ width: "100%" }}>
                <CallStatsPieChartLegend
                  data={pieChartData.filter((data) =>
                    filter.showingCallResults.includes(data.id)
                  )}
                />
              </Paper>
            </Stack>

            <RelativeBackdrop open={fetching}>
              <LinearProgress
                sx={{ position: "absolute", top: 0, width: "100%" }}
              ></LinearProgress>
            </RelativeBackdrop>
          </Stack>
        </Stack>

        <Stack gap={4} minWidth={290}>
          <Box flexGrow={0} position="relative">
            <CallResultSummaryLarge
              answerCount={answerCount || 0}
              tossUpCount={tossUpCount || 0}
              appointmentCount={appointmentCount || 0}
            ></CallResultSummaryLarge>
            <RelativeBackdrop open={fetching}>
              <LinearProgress
                sx={{ position: "absolute", top: 0, width: "100%" }}
              ></LinearProgress>
            </RelativeBackdrop>
          </Box>
          <CallProcessLogFilter
            handleFilterApply={handleFilterApply}
          ></CallProcessLogFilter>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Home;
