import {
  Box,
  Container,
  Divider,
  Fade,
  MenuItem,
  Pagination,
  PaginationItem,
  Select,
  Stack,
} from "@mui/material";
import CallListFilter from "../../features/CallHistory/CallHistoryFilter";
import { CallHistoryEntry } from "@/features/CallHistory/CallHistoryEntry";
import { CallHistoryHeader } from "@/features/CallHistory/CallHistoryHeader";
import { BreadcrumbRef, DSBreadcrumbs } from "@/components/DSBreadcrumbs";
import { useCallback, useEffect, useRef } from "react";
import { setLoadingBackdrop } from "@/store/commonSlice";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "@/store/store";
import { useSelector } from "react-redux";
import {
  CallHistoryCursor,
  CallHistoryFilter,
} from "@/features/CallHistory/CallHistoryCursor";
import {
  initCallHistory,
  setCallHistoryCurrentPage,
  setCallHistoryPageIsFetched,
  setFilteredCallHistoryNum,
  updateLocalCallHistory,
} from "@/store/callSlice";

const CALLS_PER_PAGE = 10;
let cursor: CallHistoryCursor | null = null;

const getPageNum = (length: number) =>
  Math.floor((length - 1) / CALLS_PER_PAGE + 1);

const History = () => {
  const breadcrumbRefs: BreadcrumbRef[] = [{ title: "コール履歴" }];
  const filteredCallHistoryNum = useSelector(
    (state: RootState) => state.call.filteredCallHistoryNum,
  );
  const history = useSelector((state: RootState) => state.call.callHistory);
  const page = useSelector(
    (state: RootState) => state.call.callHistoryCurrentPage,
  );
  const pageIsFetched = useSelector(
    (state: RootState) => state.call.callHistoryPageIsFetched,
  );
  const user = useSelector((state: RootState) => state.user.loggedInUser);
  const isSignedIn = useSelector((state: RootState) => state.user.isSignedIn);
  const dispatch = useDispatch<AppDispatch>();
  // 初期実行を1回にするためのフラグ
  const isInitialized = useRef(false);

  const handleFilterSubmit = useCallback(
    async (filter: CallHistoryFilter) => {
      if (
        cursor &&
        Object.keys(filter)
          .map((key) => filter[key] === cursor.filter[key])
          .reduce((prev, curr) => prev && curr, true)
      )
        return;

      dispatch(
        setLoadingBackdrop({ key: "HistoryHandleFilterSubmit", state: true }),
      );

      // コール履歴の初期化
      dispatch(initCallHistory());

      cursor = new CallHistoryCursor({ filter, callsPerPage: CALLS_PER_PAGE });

      // 最初の1ページフェッチ
      const fetched = await cursor.fetchNext();

      dispatch(setFilteredCallHistoryNum(fetched.total));
      console.log("call history total: " + fetched.total);
      dispatch(updateLocalCallHistory(fetched.records));
      const newPageIsFetched = Array.from(
        { length: getPageNum(fetched.total) },
        () => false,
      );
      newPageIsFetched[0] = true;

      dispatch(setCallHistoryPageIsFetched(newPageIsFetched));
      dispatch(setCallHistoryCurrentPage(1));

      dispatch(
        setLoadingBackdrop({ key: "HistoryHandleFilterSubmit", state: false }),
      );
    },
    [dispatch, isSignedIn, user],
  );

  const handleChangePage = useCallback(
    (e, newPage: number) => {
      if (!cursor) throw new Error("fetcher is not initialized");

      const fetch = async () => {
        dispatch(setLoadingBackdrop({ key: "HistoryPageChange", state: true }));

        const results = await cursor.fetchNext(newPage);
        dispatch(updateLocalCallHistory(results.records));
        dispatch(setCallHistoryCurrentPage(newPage));

        const newPageIsFetched = [...pageIsFetched];
        newPageIsFetched[newPage - 1] = true;
        dispatch(setCallHistoryPageIsFetched(newPageIsFetched));
        dispatch(
          setLoadingBackdrop({ key: "HistoryPageChange", state: false }),
        );
      };

      fetch();
    },
    [cursor, dispatch, history, page, pageIsFetched],
  );

  // ページ初期取得処理
  useEffect(() => {
    if (isInitialized.current || pageIsFetched.length > 0 ) return;
    console.log("初期実行", { page, pageIsFetched, isInitialized },pageIsFetched.length);
    const fetch = async () => {
      dispatch(setLoadingBackdrop({ key: "HistoryPageChange", state: true }));

      const results = await cursor.fetchNext(page);
      dispatch(updateLocalCallHistory(results.records));
      dispatch(setFilteredCallHistoryNum(results.total));
      const newPageIsFetched = [...pageIsFetched];
      newPageIsFetched[page - 1] = true;
      dispatch(setCallHistoryPageIsFetched(newPageIsFetched));
      dispatch(setLoadingBackdrop({ key: "HistoryPageChange", state: false }));
    };

     fetch();
     isInitialized.current = true;
  }, [cursor, dispatch, history, page, pageIsFetched]);

  return (
    <Container sx={{ py: 4 }} maxWidth="lg">
      <DSBreadcrumbs breadcrumbRefs={breadcrumbRefs}></DSBreadcrumbs>
      <CallListFilter onSubmit={handleFilterSubmit}></CallListFilter>

      <Divider sx={{ mt: 4 }}></Divider>

      <Box mt={4} mb={1}>
        <CallHistoryHeader></CallHistoryHeader>
      </Box>
      <Stack gap={1.2}>
        {Object.values(history).map((record, i) => (
          <Fade in={true} key={i} timeout={200}>
            <Box>
              <CallHistoryEntry record={record} key={i}></CallHistoryEntry>
            </Box>
          </Fade>
        ))}
      </Stack>
      <Pagination
        count={getPageNum(filteredCallHistoryNum)}
        sx={{
          pt: 2,
          pb: 6,
          "& .MuiPagination-ul": { justifyContent: "center" },
        }}
        size="large"
        page={page}
        onChange={handleChangePage}
        siblingCount={3}
        renderItem={(item) => {
          if (item.page !== page)
            return <PaginationItem {...item}></PaginationItem>;
          return (
            <Select
              value={page}
              onChange={(event) =>
                handleChangePage(null, Number(event.target.value))
              }
              size="small"
            >
              {Array.from({ length: getPageNum(filteredCallHistoryNum) }).map(
                (_, i) => (
                  <MenuItem value={i + 1}>{i + 1}</MenuItem>
                ),
              )}
            </Select>
          );
        }}
      ></Pagination>
    </Container>
  );
};

export default History;
