import { Box, Button, Container, Grid, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { BreadcrumbRef, DSBreadcrumbs } from "@/components/DSBreadcrumbs";
import { request } from "@/models/telai-backend/client";
import {BLockedContact} from "@/models/BlockedList";
import { setLoadingBackdrop, setSnackbar } from "@/store/commonSlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "@/store/store";

const BlokedList = () => {
  const { tenantId } = useParams();
  const breadcrumbRefs: BreadcrumbRef[] = useMemo(
    () => [
      {
        title: "リスト管理",
        navigateTo: `/${tenantId}/calllist`,
      },
      {
        title: "NGコールリスト",
      },
    ],
    [],
  );
  const [blockedList, setBlockedList] = useState<BLockedContact[]>([]);
  const [open, setOpen] = useState(false);
  const [newName, setNewName] = useState("");
  const [newPhone, setNewPhone] = useState("");
  const [error, setError] = useState("");
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [phoneToDelete, setPhoneToDelete] = useState("");
  const dispatch = useDispatch<AppDispatch>();

  const handleDelete = (phoneNumber: string) => {
    setPhoneToDelete(phoneNumber);
    setDeleteConfirmOpen(true);
  };

  const confirmDelete = () => {
    const convertedPhone = phoneToDelete.startsWith("0")
      ? `+81${phoneToDelete.slice(1)}`
      : phoneToDelete;

    deleteBlockContact(convertedPhone);
    setDeleteConfirmOpen(false);
    setPhoneToDelete("");
  };

  const fetchData = async () => {
    try {
      const res = await request({
        path: "/blocked_contacts",
        httpMethod: "get",
      });
       dispatch(setLoadingBackdrop({ key: "getBlock", state: true }));
      //convert phone number +81 to 1234567890
      const blockedContacts = res.data.map((contact: BLockedContact) => {
        const phone = contact.phone_number;
        const convertedPhone = phone.replace("+81", "0");
        return {
          ...contact,
          phone_number: convertedPhone,
        };
      });
      if (res.result === "error") {
        dispatch(setSnackbar({open: true, text: "データの取得に失敗しました", severity: "error" }));
        return;
      }
      setBlockedList(blockedContacts);
    } catch (error) {
      console.error("データの取得に失敗しました", error);
      dispatch(setSnackbar({open: true, text: "データの取得に失敗しました", severity: "error" }));
    }finally{
      dispatch(setLoadingBackdrop({ key: "getBlock", state: false }));
    }
  }

  const addBlockContact = async (name: string, phone: string) => {
    try {
      dispatch(setLoadingBackdrop({ key: "addBlock", state: true }));
      const response = await request({
        path: "/blocked_contacts",
        httpMethod: "post",
        params: {
          body: {
            name: name,
            phoneNumber: phone,
          },
        },
      });
      if (response.result === "error") {
        dispatch(setSnackbar({open: true, text: "データの追加に失敗しました", severity: "error" }));
        return;
      }
      dispatch(setSnackbar({open: true, text: "データの追加に成功しました", severity: "success" }));
      await fetchData();
    } catch (error) {
      dispatch(setSnackbar({open: true, text: "データの追加に失敗しました", severity: "error" }));
      console.error("データの追加に失敗しました", error);
    } finally {
      dispatch(setLoadingBackdrop({ key: "addBlock", state: false }));
    }
  }

  const deleteBlockContact = async (phoneNumber: string) => {
    try {
      dispatch(setLoadingBackdrop({ key: "deleteBlock", state: true }));
      const response  = await request({
        path: "/blocked_contacts/{phoneNumber}",
        httpMethod: "delete",
        params: {
          paths: {
            phoneNumber: phoneNumber,
          },
        },
      });
      if (response.result === "error") {
        dispatch(setSnackbar({open: true, text: "データの削除に失敗しました", severity: "error" }));
        return;
      }
      dispatch(setSnackbar({open: true, text: "データの削除に成功しました", severity: "success" }));
      await fetchData();
    } catch (error) {
      dispatch(setSnackbar({open: true, text: "データの削除に失敗しました", severity: "error" }));
      console.error("データの削除に失敗しました", error);
    } finally {
      dispatch(setLoadingBackdrop({ key: "deleteBlock", state: false }));
    }
  }

  const handleAdd = () => {
    const phoneRegex = /^0\d{1,4}\d{1,4}\d{4}$/;
    if (!phoneRegex.test(newPhone)) {
      setError("無効な電話番号です。形式は08012345678や0312345678です。");
      return;
    }

    // phoneNumberが0の場合は+81に変換
    const convertNewPhone = newPhone.startsWith("0")
      ? `+81${newPhone.slice(1)}`
      : newPhone;
    
    addBlockContact(newName, convertNewPhone);

    setNewName("");
    setNewPhone("");
    setOpen(false);
    setError("");
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setError("");
  };

  const handleDeleteConfirmClose = () => {
    setDeleteConfirmOpen(false);
    setPhoneToDelete("");
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Box sx={{ mb: 2 }}>
        <DSBreadcrumbs breadcrumbRefs={breadcrumbRefs}></DSBreadcrumbs>
      </Box>

      <Box display="flex" gap={2}>
        <Button
          sx={{ width: 240 }}
          color="secondary"
          variant="contained"
          onClick={handleClickOpen}
          startIcon={<Add />}
        >
          <Typography>コールNG電話番号追加</Typography>
        </Button>
      </Box>
      
      <Grid container spacing={4} mt={2} columns={12}>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>電話番号</TableCell>
                  <TableCell>名前</TableCell>
                  <TableCell>操作</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {blockedList.map((row) => (
                  <TableRow key={row.phone_number}>
                    <TableCell>{row.phone_number}</TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>
                      <Button
                        color="secondary"
                        onClick={() => handleDelete(row.phone_number)}
                        startIcon={<Delete />}
                      >
                        削除
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>NGコール追加</DialogTitle>
        <DialogContent>
          <DialogContentText>
            新しいNGコールを追加してください。
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="名前"
            type="text"
            fullWidth
            variant="standard"
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
          />
          <TextField
            margin="dense"
            label="電話番号"
            type="text"
            fullWidth
            variant="standard"
            value={newPhone}
            onChange={(e) => setNewPhone(e.target.value)}
            error={!!error}
            helperText={error}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>キャンセル</Button>
          <Button onClick={handleAdd}>追加</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={deleteConfirmOpen} onClose={handleDeleteConfirmClose}>
        <DialogTitle>削除確認</DialogTitle>
        <DialogContent>
          <DialogContentText>
            本当にこの電話番号を削除しますか？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteConfirmClose}>キャンセル</Button>
          <Button onClick={confirmDelete} color="secondary">削除</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default BlokedList;