import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { Add, Block } from "@mui/icons-material";
import CallListCard from "@/features/CallList/CallListCard";
import { useNavigate, useParams } from "react-router-dom";
import { useCallListMetadata } from "@/hooks/useCallList";
import { useCompanyUsers } from "@/hooks/useCompanyUsers";
import { useMemo } from "react";
import { BreadcrumbRef, DSBreadcrumbs } from "@/components/DSBreadcrumbs";
import formatTimestamp from "@/utils/formatTimestamp";

const CallList = () => {
  const callListMetadata = useCallListMetadata();
  const navigate = useNavigate();
  const users = useCompanyUsers();
  const breadcrumbRefs: BreadcrumbRef[] = useMemo(
    () => [
      {
        title: "リスト管理",
      },
    ],
    [],
  );
  const { tenantId } = useParams();

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Box sx={{ mb: 2 }}>
        <DSBreadcrumbs breadcrumbRefs={breadcrumbRefs}></DSBreadcrumbs>
      </Box>

      <Box display="flex" gap={2}>
        <Button
          sx={{ width: 145 }}
          color="secondary"
          variant="contained"
          onClick={() => navigate(`/${tenantId}/calllist/add`)}
          startIcon={<Add />}
        >
          <Typography>追加</Typography>
        </Button>

        <Button
          sx={{ width: 200 }}
          color="error"
          variant="contained"
          onClick={() => navigate(`/${tenantId}/calllist/BlockedList`)}
          startIcon={<Block />}
        >
          <Typography>NGコールリスト</Typography>
        </Button>
      </Box>


      <Grid container spacing={4} mt={2} columns={12}>
        {Object.values(callListMetadata)
          // .sort((a, b) => b.createdAt.toMillis() - a.createdAt.toMillis())
          .map((data) => (
            <Grid item xs={6} key={data.id}>
              <CallListCard
                name={data.name}
                memo={data.memo}
                createdAt={formatTimestamp(data.createdAt)}
                creator={
                  users[data.creatorId] ? users[data.creatorId].name : ""
                }
                searchWord={data.searchWord}
                id={data.id}
              />
            </Grid>
          ))}
      </Grid>
    </Container>
  );
};

export default CallList;
