// ページネーション用のクラス

import { CallListItem } from "@/models/CallList";
import memoize from "lodash/memoize";
import { request } from "@/models/telai-backend/client";

class CallListItemCursor {
  private readonly callListId: string;
  private readonly itemsPerPage: number;
  private readonly callListItemIndex: number | null;
  private currentPage: number = 0; // zero-based
  private pageIsFetched?: boolean[];
  private sort: {
    field: keyof CallListItem;
    order: "asc" | "desc";
  } | null;

  constructor({
    callListId,
    itemsPerPage,
    callListItemIndex,
    sort,
  }: {
    callListId: string;
    itemsPerPage: number;
    callListItemIndex?: number;
    sort?: {
      field: keyof CallListItem;
      order: "asc" | "desc";
    };
  }) {
    this.callListId = callListId;
    this.itemsPerPage = itemsPerPage;
    this.currentPage = 0;
    this.callListItemIndex = callListItemIndex || null;
    this.sort = sort || null;
  }

  totalNum = memoize(async (): Promise<number> => {
    // const q = query(getCollectionRef(`/companies/${this.companyId}/callListItems_v2`), where("callListId", "==", this.callListId))
    // const total = (await getCountFromServer(q)).data().count

    const result = await request({
      path: "/contact_lists/{listId}",
      httpMethod: "get",
      params: {
        paths: {
          listId: this.callListId,
        },
      },
    });

    const total = result.data.contactCount;

    console.log("totalNum", total);

    return total;
  });

  getCurrentPage = () => this.currentPage;

  fetchNext = async (newPage: number = 0): Promise<CallListItem[]> => {
    console.log("fetchNext", newPage, this.callListItemIndex);
    const result = await request({
      path: "/contact_lists/{listId}/contacts",
      httpMethod: "get",
      params: {
        paths: {
          listId: this.callListId,
        },
        query: {
          limit: this.itemsPerPage,
          offset: newPage * this.itemsPerPage,
          orderBy: undefined, // or any appropriate value
          order: undefined, // or "desc"
          query: undefined, // or any appropriate value
        },
      },
    });

    console.log("fetchNext", result.data);
    if (result.data) {
      const createOptionalInfo = (metadata: {
        [key: string]: string | number | boolean;
      }): { [key: string]: string } => {
        const optionalInfo: { [key: string]: string } = {};
        for (const key in metadata) {
          optionalInfo[key] = metadata[key].toString();
        }
        return optionalInfo;
      };

      const items: CallListItem[] = result.data.contacts.map(
        (contact) => ({
          id: contact.id,
          index: contact.contactListSequence,
          callListId: this.callListId,
          companyName: contact.name,
          optionalInfo: createOptionalInfo(contact.metadata),
          lastCallDate: contact.latestCallCalledAt ? new Date(contact.latestCallCalledAt) : null,
          lastCallResult: contact.latestCallNoteResult,
          latestCallNoteContent: contact.latestCallNoteContent,
          latestCallNextCallScheduledAt: contact.latestCallNextCallScheduledAt ? new Date(contact.latestCallNextCallScheduledAt) : null,
          lastCallHistoryIds: [],
          nextCallDate: new Date(),
          banned: false,
          phoneNumber: contact.phone_number,
        }),
      );
      console.log("fetchNext", items);

      return items;
    }
  };
}

export default CallListItemCursor;
