import { Box, Container, Divider, Fade, Skeleton, Stack } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "@/store/store";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { BreadcrumbRef, DSBreadcrumbs } from "@/components/DSBreadcrumbs";
import RedialManual from "@/features/Redial/RedialManual";
import RedialAi from "@/features/Redial/RedialAi";
import { useRedialCalls } from "@/hooks/useRedialCalls";
import { useCompanyPhoneNumbers } from "@/hooks/useCompanyPhoneNumbers";
import { request } from "@/models/telai-backend/client";

const Redial = React.memo(() => {
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.user.loggedInUser);
  const isSignedIn = useSelector((state: RootState) => state.user.isSignedIn);
  const { tenantId } = useParams();
  const {
    aiRedialCalls,
    manualRedialCalls,
    manualRedialCallsOnlyTags,
    aiRedialCallList,
    internalFetch,
    fetchManualRedialCallsOnlyTags,
    fetchManualRedialCalls,
    fetchAINurturingContactList,
  } = useRedialCalls();
  const [maxAssigneesForAllCallBatches, setMaxAssigneesForAllCallBatches] =
    useState<number>(0);
  const [CallBatchAssignees, setCallBatchAssignees] = useState<string[]>([]);
  const companyPhoneNumbers = useCompanyPhoneNumbers();
  const breadcrumbRefs: BreadcrumbRef[] = useMemo(
    () => [{ title: "再コール" }],
    []
  );
  const loading = useSelector(
    (state: RootState) => state.common.loadingBackdrop.fetchNurturingCalls
  );
  const showSkeleton = useMemo(
    () => !user.permissions.includes("callBatches.create") || loading,
    [user, loading]
  );

  console.log("Redia==>", aiRedialCalls, manualRedialCalls);

  // 権限がないならhomeへ遷移
  useEffect(() => {
    if (!isSignedIn || !user) return;
    if (!user.permissions.includes("callBatches.create")) {
      navigate(`/${tenantId}`);
    }
    fetchCallBatchAssignees();
    fetchMaxAssigneesForAllCallBatches();
  }, [isSignedIn, user, tenantId]);

  const reFetchNurturingCall = useCallback(async () => {
    await internalFetch();
  }, [manualRedialCalls]);

  //コールバッチの割り当てユーザーを取得
  async function fetchCallBatchAssignees() {
    const res = await request({
      path: "/call_batches/actives",
      httpMethod: "get",
    });
    console.log("fetchCallBatchAssignees", res);
    if (res.result === "success") {
      const assignees: string[] = [];
      res.data.callBatches.forEach((batch) => {
        batch.assigneeIds.forEach((assignee) => {
          assignees.push(assignee);
        });
      });
      console.log("fetchCallBatchAssignees", assignees);
      setCallBatchAssignees(assignees);
    }
  }

  // 全体の最大割り当て数を取得
  async function fetchMaxAssigneesForAllCallBatches() {
    const res = await request({
      path: "/settings",
      httpMethod: "get",
    });
    console.log("fetchMaxAssigneesForAllCallBatches", res);
    if (
      res.result === "success" &&
      res.data.maxAssigneesForAllCallBatches >= 0
    ) {
      setMaxAssigneesForAllCallBatches(res.data.maxAssigneesForAllCallBatches);
    }
  }

  return (
    <Container sx={{ py: 4, maxWidth: 900, mx: "auto" }}>
      <Box mb={2}>
        <DSBreadcrumbs breadcrumbRefs={breadcrumbRefs}></DSBreadcrumbs>
      </Box>
      <Box width={722} mx="auto">
        {/* <Tabs value="manual" sx={{ mb: 2 }}>
          <Tab label="手動コール" value="manual" />
          <Tab label="AIコール" value="ai" />
        </Tabs> */}
        {showSkeleton ? (
          <Stack maxWidth={786} mx="auto" gap={4}>
            <Skeleton
              height={607}
              variant="rectangular"
              animation="wave"
            ></Skeleton>
            <Skeleton
              height={607}
              variant="rectangular"
              animation="wave"
            ></Skeleton>
          </Stack>
        ) : (
          <Stack gap={4}>
            <Fade in={!showSkeleton}>
              <div>
                <RedialManual
                  manualRedialCalls={manualRedialCalls}
                  reFetchNurturingCall={reFetchNurturingCall}
                  changePageOnManualRedial={fetchManualRedialCalls}
                  manualRedialCallsOnlyTags={manualRedialCallsOnlyTags}
                  changePageOnManualRedialOnlyTags={
                    fetchManualRedialCallsOnlyTags
                  }
                ></RedialManual>
              </div>
            </Fade>
            <Divider></Divider>
            <Fade in={!showSkeleton}>
              <div>
                <RedialAi
                  nurturingList={aiRedialCallList}
                  companyPhoneNumbers={companyPhoneNumbers}
                  fetchContactList={fetchAINurturingContactList}
                  remainingAssignees={
                    maxAssigneesForAllCallBatches - CallBatchAssignees.length
                  }
                  onCall={() => {
                    fetchCallBatchAssignees();
                  }}
                ></RedialAi>
              </div>
            </Fade>
          </Stack>
        )}
      </Box>
    </Container>
  );
});

export default Redial;
