import {
  Box,
  Button,
  Checkbox,
  Divider,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { Option, SimpleSelect } from "@/components/SimpleSelect";
import { useCallListMetadata } from "@/hooks/useCallList";
import { useScripts } from "@/hooks/useScripts";
import { useCallback, useEffect, useState } from "react";
import {
  CallStatsFilter,
  CallStatsPeriod,
  callStatsPeriodLabels,
  callStatsPeriods,
} from "@/google/callStats";
import { RootState } from "@/store/store";
import { useSelector } from "react-redux";
import { HistoryOutlined, PhoneOutlined } from "@mui/icons-material";
import { sortCallNoteResults } from "@/models/CallHistory";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

const weekdayOptions = [
  { value: "SUNDAY", label: "日" },
  { value: "MONDAY", label: "月" },
  { value: "TUESDAY", label: "火" },
  { value: "WEDNESDAY", label: "水" },
  { value: "THURSDAY", label: "木" },
  { value: "FRIDAY", label: "金" },
  { value: "SATURDAY", label: "土" },
] as const;

type Props = {
  handleFilterApply: (filter: CallStatsFilter) => void;
};

const CallProcessLogFilter = ({ handleFilterApply }: Props) => {
  const callNoteResults = useSelector(
    (state: RootState) => state.company.config.callNoteResults
  );
  const scripts = useScripts();
  const callLists = useCallListMetadata();
  const [filter, setFilter] = useState<CallStatsFilter>({
    period: "TODAY",
    customPeriod: {
      startDate: dayjs(),
      endDate: dayjs(),
    },
    scriptId: "",
    callListId: "",
    daysOfWeek: [],
    showingCallResults: Object.values(callNoteResults)
      .filter(
        (result) =>
          (["TOSSUP", "AI", "GENERAL"].includes(result.type) ||
            result.achievedAppointment) &&
          ["NEVER_CALLED", "ERROR"].includes(result.id) === false
      )
      .map((result) => result.id),
  });

  const handleWeekdays = (
    event: React.MouseEvent<HTMLElement>,
    daysOfWeek: string[]
  ) => {
    setFilter({
      ...filter,
      daysOfWeek,
    });
  };

  const handleCallResultCheckboxChange = useCallback(
    (result: CallStatsFilter["showingCallResults"][number], state: boolean) => {
      setFilter({
        ...filter,
        showingCallResults: state
          ? filter.showingCallResults.concat(result)
          : filter.showingCallResults.filter((r) => r !== result),
      });
    },
    [filter]
  );

  const handleClearFilterClick = useCallback(() => {
    setFilter({
      period: "TODAY",
      scriptId: "",
      callListId: "",
      daysOfWeek: [],
      showingCallResults: Object.values(callNoteResults)
        .filter(
          (result) =>
            (["TOSSUP", "AI", "GENERAL"].includes(result.type) ||
              result.achievedAppointment) &&
            ["NEVER_CALLED", "ERROR"].includes(result.id) === false
        )
        .map((result) => result.id),
    });
  }, [callNoteResults]);

  // 初回読み込み時に表示する項目を設定
  useEffect(() => {
    setFilter((prev) => ({
      ...prev,
      showingCallResults: Object.values(callNoteResults)
        .filter(
          (result) =>
            (["TOSSUP", "AI", "GENERAL"].includes(result.type) ||
              result.achievedAppointment) &&
            ["NEVER_CALLED", "ERROR"].includes(result.id) === false
        )
        .map((result) => result.id),
    }));
  }, [callNoteResults]);

  return (
    <Stack
      borderRadius={2}
      display="flex"
      sx={{
        ".base-Select-popup": {
          zIndex: 100,
          maxHeight: 400,
        },
      }}
      gap={2}
      minWidth={240}
      maxWidth={360}
    >
      <Stack textAlign="left" gap={0.5}>
        <Box display="flex" gap={1} mb={1}>
          <Stack direction="row" alignItems="center" gap={0.5}>
            <Typography>検索条件</Typography>
          </Stack>

          <Button
            variant="contained"
            sx={{
              borderRadius: "8px",
              padding: "6px 10px",
              lineHeight: 1,
              fontSize: "0.80rem",
              boxShadow: "0px 2px 4px rgba(0,0,0, 0.05)",
              ml: "auto",
            }}
            onClick={handleClearFilterClick}
            color="greyNormal"
          >
            クリア
          </Button>
          <Button
            variant="contained"
            sx={{
              borderRadius: "8px",
              padding: "6px 10px",
              lineHeight: 1,
              fontSize: "0.80rem",
              boxShadow: "0px 2px 4px rgba(0,0,0, 0.05)",
            }}
            onClick={() => handleFilterApply(filter)}
          >
            適用
          </Button>
        </Box>

        <Box display="flex" justifyContent="space-between">
          <Typography
            sx={{ mr: 1 }}
            fontSize="0.80rem"
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            スクリプト
          </Typography>
          <SimpleSelect
            value={filter.scriptId}
            onChange={(_, newValue: string) =>
              setFilter({ ...filter, scriptId: newValue })
            }
            style={{ width: 160 }}
            size="xs"
          >
            <Option value="" size="xs">
              全て
            </Option>
            {Object.entries(scripts ?? {}).map(([id, metadata]) => (
              <Option value={id} size="xs" key={id}>
                {metadata.name || "名前なし"}
              </Option>
            ))}
          </SimpleSelect>
        </Box>

        <Box display="flex" justifyContent="space-between">
          <Typography
            sx={{ mr: 1 }}
            fontSize="0.80rem"
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            リスト
          </Typography>
          <SimpleSelect
            value={filter.callListId}
            onChange={(_, newValue: string) =>
              setFilter({ ...filter, callListId: newValue })
            }
            style={{ width: 160 }}
            size="xs"
          >
            <Option value="" size="xs">
              全て
            </Option>
            {Object.entries(callLists ?? {}).map(([id, metadata]) => (
              <Option value={id} size="xs" key={id}>
                {metadata.name || "名前なし"}
              </Option>
            ))}
          </SimpleSelect>
        </Box>
      </Stack>

      <Divider></Divider>

      <Stack>
        <Stack direction="row" mb={1} gap={0.5} alignItems="center">
          <HistoryOutlined fontSize="small"></HistoryOutlined>
          <Typography>検索期間</Typography>
        </Stack>

        <SimpleSelect
          value={filter.period}
          onChange={(_, newValue: CallStatsPeriod) => {
            if (newValue === "THIS_YEAR") {
              setFilter({ ...filter, period: newValue, daysOfWeek: [] });
            } else {
              setFilter({ ...filter, period: newValue });
            }
          }}
          size="xs"
        >
          {callStatsPeriods.map((period) => (
            <Option value={period} key={period} size="xs">
              {callStatsPeriodLabels[period]}
            </Option>
          ))}
        </SimpleSelect>

        {filter.period === "CUSTOM" && (
          <Box mt={2}>
            <Stack direction="row" gap={1}>
              <DatePicker
                value={filter.customPeriod?.startDate}
                onChange={(date) => {
                  if (date === null || date === undefined) {
                    return;
                  }
                  setFilter({
                    ...filter,
                    customPeriod: {
                      ...filter.customPeriod,
                      startDate: date,
                    },
                  });
                }}
                slotProps={{ textField: { size: "small" } }}
              />
              <Stack
                display="flex"
                alignItems="center"
                justifyContent="center"
                fontSize="0.80rem"
              >
                〜
              </Stack>
              <DatePicker
                value={filter.customPeriod?.endDate}
                onChange={(date) => {
                  if (date === null || date === undefined) {
                    return;
                  }
                  setFilter({
                    ...filter,
                    customPeriod: {
                      ...filter.customPeriod,
                      endDate: date,
                    },
                  });
                }}
                slotProps={{ textField: { size: "small" } }}
              />
            </Stack>
          </Box>
        )}

        <Box mt={2}>
          <ToggleButtonGroup
            size="small"
            sx={{ bgcolor: "#fff" }}
            value={filter.period === "THIS_YEAR" ? [] : filter.daysOfWeek}
            onChange={handleWeekdays}
            disabled={filter.period === "THIS_YEAR"}
          >
            {weekdayOptions.map((day) => (
              <ToggleButton
                key={day.value}
                value={day.value}
                sx={{
                  width: 34,
                  height: 34,
                  // "&.Mui-selected": {
                  //   color: "#fff",
                  //   bgcolor: "#1976d2"
                  // }
                }}
              >
                {day.label}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Box>
      </Stack>

      <Divider></Divider>

      <Stack>
        <Stack direction="row" mb={1} gap={0.5} alignItems="center">
          <PhoneOutlined fontSize="small"></PhoneOutlined>
          <Typography>コール結果</Typography>
        </Stack>

        <Stack gap={1} direction="row">
          <Stack gap={1}>
            {sortCallNoteResults(
              Object.values(callNoteResults).filter(
                (result) =>
                  (["TOSSUP", "AI", "GENERAL"].includes(result.type) ||
                    result.achievedAppointment) &&
                  ["NEVER_CALLED", "ERROR"].includes(result.id) === false
              )
            )
              .slice(0, 10)
              .map((result, i) => (
                <Stack direction="row" alignItems="center" gap={1} key={i}>
                  <Checkbox
                    size="small"
                    sx={{ p: 0 }}
                    checked={filter.showingCallResults.includes(result.id)}
                    onChange={(_, checked) =>
                      handleCallResultCheckboxChange(result.id, checked)
                    }
                  ></Checkbox>
                  <Typography fontSize="0.85rem">
                    {callNoteResults[result.id]?.name || result.id}
                  </Typography>
                </Stack>
              ))}
          </Stack>
          <Stack gap={1}>
            {sortCallNoteResults(
              Object.values(callNoteResults).filter(
                (result) =>
                  (["TOSSUP", "AI", "GENERAL"].includes(result.type) ||
                    result.achievedAppointment) &&
                  ["NEVER_CALLED", "ERROR"].includes(result.id) === false
              )
            )
              .slice(10)
              .map((result, i) => (
                <Stack direction="row" alignItems="center" gap={1} key={i}>
                  <Checkbox
                    size="small"
                    sx={{ p: 0 }}
                    checked={filter.showingCallResults.includes(result.id)}
                    onChange={(_, checked) =>
                      handleCallResultCheckboxChange(result.id, checked)
                    }
                  ></Checkbox>
                  <Typography fontSize="0.85rem">
                    {callNoteResults[result.id]?.name || result.id}
                  </Typography>
                </Stack>
              ))}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default CallProcessLogFilter;
