import { RootState } from "@/store/store";
import {
  Call as MuiCall,
  CallEnd,
  PhoneCallback,
  PhoneForwarded,
} from "@mui/icons-material";
import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { CallScreenDialog } from "./CallScreenDialog";
import { Call } from "@/models/Call";

// const buttonSx = {
//   borderRadius: "50%",
//   width: 64,
//   height: 64,
//   "& .MuiSvgIcon-root": {
//     fontSize: "1.8rem",
//   },
// };

export const CallPad = ({
  call,
  onEndCall,
  displayName,
  onUnmute,
}: {
  call?: Call;
  onEndCall: () => void;
  onUnmute: () => void;
  displayName: string;
}) => {
  const [callScreenDialog, setCallScreenDialog] = useState(false);
  const [callInProgress, setCallInProgress] = useState(false);
  const twilioCall = useSelector((state: RootState) => state.call.twilioCall);

  const handleAcceptCall = useCallback(() => {
    console.log(twilioCall);
    if (twilioCall === null || twilioCall === undefined) {
      console.log("twilioCall is null");
      return;
    }
    if (onUnmute) onUnmute();
    setCallInProgress(true);
    // call.accept()
    if (!twilioCall) throw new Error("missing call instance");
    twilioCall.mute(false);
  }, [twilioCall]);

  const handleEndCall = async () => {
    onEndCall();
    twilioCall.disconnect();
    twilioCall.status();
    setCallInProgress(false);
  };

  // const handleMute = () => {
  //   twilioCall.mute(!twilioCall.isMuted);
  // };

  return (
    <Paper
      sx={{
        bgcolor: callInProgress ? "#2b2b2b" : "#6e6e6e",
        transition: "0.3s",
        color: "#fff",
        p: 4,
        borderRadius: 0,
        flexGrow: 1,
      }}
    >
      <Stack>
        <Typography
          variant="h4"
          alignItems="center"
          display="flex"
          justifyContent="center"
          gap={1}
        >
          <Box mb={-1}>
            {call?.direction == "INBOUND" ? (
              <PhoneCallback
                htmlColor="#7FB50B"
                fontSize="large"
              ></PhoneCallback>
            ) : (
              <PhoneForwarded
                htmlColor="#E15E14"
                fontSize="large"
              ></PhoneForwarded>
            )}
          </Box>
          {(call?.direction === "INBOUND" ? call?.from : call?.to) ||
            "(電話番号不明)"}
        </Typography>
        <Typography>
          {callInProgress
            ? "通話中..."
            : call?.direction === "INBOUND"
              ? "着信中..."
              : "発信中..."}
        </Typography>
        <Box mt={4}>
          {/* <Avatar sx={{ width: 82, height: 82, mx: "auto", mb: 2 }}></Avatar> */}
          <Typography variant="h6">{displayName || "(不明)"}</Typography>
        </Box>
        {/* <Box display="flex" gap={4} mt={4} justifyContent="center">
          <Stack>
            <Button size="large" color={call && call.isMuted ? "white" : "greyDarken"} variant="contained" sx={buttonSx} disabled={!callInProgress} onClick={handleMute}>
              <MicOff fontSize="large"></MicOff>
            </Button>
            <Typography fontSize="0.875rem" mt={1}>
              消音
            </Typography>
          </Stack>
          <Stack>
            <Button size="large" color="greyDarken" variant="contained" sx={buttonSx} disabled={!callInProgress}>
              <Dialpad fontSize="large"></Dialpad>
            </Button>
            <Typography fontSize="0.875rem" mt={1}>
              キーパッド
            </Typography>
          </Stack>
          <Stack>
            <Button size="large" color="greyDarken" variant="contained" sx={buttonSx} disabled={!callInProgress}>
            <VolumeUp fontSize="large"></VolumeUp>
            </Button>
            <Box mt={1} maxWidth={64}>
              <Typography mx="-100%" fontSize="0.875rem" whiteSpace="nowrap" textAlign="center">
                オーディオ設定
              </Typography>
            </Box>
          </Stack>
        </Box>
        <Box display="flex" gap={4} mt={4} justifyContent="center">
          <Stack>
            <Button size="large" color="greyDarken" variant="contained" sx={buttonSx} disabled={!callInProgress}>
              <PhonePaused fontSize="large"></PhonePaused>
            </Button>
            <Typography fontSize="0.875rem" mt={1}>
              保留
            </Typography>
          </Stack>
          <Stack>
            <Button size="large" color="greyDarken" variant="contained" sx={buttonSx} disabled={!callInProgress}>
              <PhoneForwarded fontSize="large"></PhoneForwarded>
            </Button>
            <Typography fontSize="0.875rem" mt={1}>
              転送
            </Typography>
          </Stack>
        </Box> */}

        <Button
          variant="contained"
          sx={{ mt: 4, mx: "auto" }}
          size="large"
          onClick={
            callInProgress ? () => setCallScreenDialog(true) : handleAcceptCall
          }
          color={callInProgress ? "error" : "green"}
          startIcon={callInProgress ? <CallEnd /> : <MuiCall />}
          disabled={twilioCall === null || twilioCall === undefined}
        >
          {callInProgress ? "通話終了" : "ミュート解除"}
        </Button>
      </Stack>
      <CallScreenDialog
        open={callScreenDialog}
        onCancel={() => setCallScreenDialog(false)}
        onConfirm={handleEndCall}
      ></CallScreenDialog>
    </Paper>
  );
};
