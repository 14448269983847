import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type redialState = {
    // 再コールページのページネーション
    redialPage: {
        manualRedialCallOffset: number;
        manualRedialCallLimit: number;
        manualRedialCallOnlyTagsOffset: number;
        manualRedialCallOnlyTagsLimit: number;
    }
}

const initialState: redialState = {
    redialPage: {
        manualRedialCallOffset: 0,
        manualRedialCallLimit: 10,
        manualRedialCallOnlyTagsOffset: 0,
        manualRedialCallOnlyTagsLimit: 10,
    }
}

const redialCallStateSlice = createSlice({
    name: "redial",
    initialState,
    reducers: {
        setManualRedialPage: (state, action: PayloadAction<{ offset: number, limit: number }>) => {
            state.redialPage.manualRedialCallOffset = action.payload.offset;
            state.redialPage.manualRedialCallLimit = action.payload.limit;
        },
        setAiTagOnlyPage: (state, action: PayloadAction<{ offset: number, limit: number }>) => {
            state.redialPage.manualRedialCallOnlyTagsOffset = action.payload.offset;
            state.redialPage.manualRedialCallOnlyTagsLimit = action.payload.limit;
        }
    }
});

export const {
    setManualRedialPage,
    setAiTagOnlyPage,
} = redialCallStateSlice.actions;

export const selectManualRedialPage = (state: { redial: redialState }) => state.redial.redialPage;
export default redialCallStateSlice.reducer;