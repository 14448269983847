import { BreadcrumbRef, DSBreadcrumbs } from "@/components/DSBreadcrumbs";
import { useCallListMetadata } from "@/hooks/useCallList";
import { useScripts } from "@/hooks/useScripts";
import { useCompanyUsers } from "@/hooks/useCompanyUsers";
import { setLoadingBackdrop, setSnackbar } from "@/store/commonSlice";
import { AppDispatch, RootState } from "@/store/store";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  CallOutlined,
  ContactPhoneOutlined,
  LocalPhoneOutlined,
  PersonAddOutlined,
  ReceiptLongOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Skeleton,
  Stack,
  TextField,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { GridSortModel } from "@mui/x-data-grid";
import { parseCallRangeString } from "@/features/CallList/CallListRangeParser";
import RelativeBackdrop from "@/components/RelativeBackdrop";
import CallListItemCursor from "@/features/CallList/CallListItemCursor";
import { updateLocalCallListItems } from "@/store/callSlice";
import formatTimestamp from "@/utils/formatTimestamp";
import CallListTable from "@/features/CallList/CallListTable";
import { CallListItem } from "@/models/CallList";
import { useCompanyPhoneNumbers } from "@/hooks/useCompanyPhoneNumbers";
import { request } from "@/models/telai-backend/client";

type FormInput = {
  callRangeMax: string;
  callNum: number;
  callAiNum: number;
};

const callListItemsPerPage = 100;

let callListItemCursor: CallListItemCursor | null = null;

const Call = () => {
  const callListMetadata = useCallListMetadata();
  const scripts = useScripts();
  const navigate = useNavigate();
  const { tenantId } = useParams();
  const dispatch = useDispatch<AppDispatch>();

  const companyPhoneNumbers = useCompanyPhoneNumbers();
  const [callListId, setCallListId] = useState("");
  const [scriptId, setScriptId] = useState("");
  const [processName, setProcessName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [rowSelection, setRowSelection] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [fetching, setFetching] = useState(false);
  const [rowCount, setRowCount] = useState(0); // ページネーション用
  const user = useSelector((state: RootState) => state.user.loggedInUser);
  const [maxAssigneesForAllCallBatches, setMaxAssigneesForAllCallBatches] =
    useState<number>(9999);
  const [selectedUsersValidationError, setSelectedUsersValidationError] =
    useState(""); // エラーメッセージの状態を追加
  const [CallBatchAssignees, setCallBatchAssignees] = useState<string[]>([]);
  const callListItems = useSelector((state: RootState) =>
    Object.values(state.call.callListItems[callListId] || {})
  );
  const [neverCalledItemIndex, setNeverCalledItemIndex] = useState<
    number | null
  >(null);

  const breadcrumbRefs: BreadcrumbRef[] = useMemo(
    () => [{ title: "AIコール" }],
    []
  );

  //最初の行番号と長さ
  const [callStartPoint, setcallStartPoint] = useState(0);
  const [callEndPoint, setCallEndPoint] = useState(0);
  const [callRange, setCallRange] = useState(0);

  // 権限がないならhomeへ遷移
  useEffect(() => {
    setLoadingBackdrop({ key: "AICallNavigate", state: true });
    //コールバッチの割り当てユーザーを取得
    fetchCallBatchAssignees();
    fetchMaxAssigneesForAllCallBatches();

    if (!user.online) return;
    if (!user.permissions.includes("callBatches.create")) {
      navigate(`/${tenantId}/`);
    }

    setLoadingBackdrop({ key: "AICallNavigate", state: false });
  }, [user.online]);

  // callListItemsのフェッチ (ページネーション付き)
  useEffect(() => {
    if (!user.tenantId || !callListId) return;

    const fetch = async () => {
      setFetching(true);
      callListItemCursor = new CallListItemCursor({
        callListId,
        itemsPerPage: callListItemsPerPage,
      });
      setRowCount(await callListItemCursor.totalNum());
      const results = await callListItemCursor.fetchNext(0);
      console.log(results);
      if (results.length) {
        dispatch(updateLocalCallListItems(results));
      }
      setFetching(false);
    };

    fetch();
  }, [callListId]);

  // ページネーション
  const handleChangePage = useCallback(
    (e, newPage: number) => {
      if (!callListId) return;
      if (!callListItemCursor) throw new Error("fetcher is not initialized");

      const fetch = async () => {
        setFetching(true);

        const results = await callListItemCursor.fetchNext(newPage);
        if (results.length) dispatch(updateLocalCallListItems(results));

        setFetching(false);
      };

      fetch();
    },
    [callListItemCursor, dispatch]
  );

  const handleChangeSort = useCallback(
    async (sortModel: GridSortModel) => {
      console.log(sortModel);
      callListItemCursor = new CallListItemCursor({
        callListId,
        itemsPerPage: 20,
        sort: sortModel.length
          ? {
              field: sortModel[0].field as keyof CallListItem,
              order: sortModel[0].sort,
            }
          : undefined,
      });

      setFetching(true);
      try {
        const results = await callListItemCursor.fetchNext(0);
        if (results.length) dispatch(updateLocalCallListItems(results));
      } catch (e) {
        console.error(e);
      } finally {
        setFetching(false);
      }
    },
    [callListId, user.tenantId]
  );

  /**
   * 選択されたユーザーが最大割り当て数を超えているかどうかをチェック
   */
  useEffect(() => {
    if (
      selectedUsers.length >
      maxAssigneesForAllCallBatches - CallBatchAssignees.length
    ) {
      setSelectedUsersValidationError(
        `ご利用のプランで同時に着席いただける人数は最大${maxAssigneesForAllCallBatches}人までです。`
      );
    } else {
      setSelectedUsersValidationError("");
    }
  }, [selectedUsers]);

  const schema = useMemo(
    () =>
      yup.object({
        callRangeMax: yup
          .string()
          .nullable()
          .typeError("数値のみ有効です。")
          .required("コール範囲は必須です。")
          .test(
            "range-test",
            "「(開始番号)-(終了番号)」で指定してください。",
            (value) => Boolean(parseCallRangeString(value))
          )
          .test(
            "max-test",
            `${rowCount}以下の値を指定してください。`,
            (value) => {
              const result = parseCallRangeString(value);
              return result && result.max <= rowCount;
            }
          ),
        callNum: yup
          .number()
          .typeError("数値のみ有効です。")
          .required("コール数は必須です。"),
        callAiNum: yup
          .number()
          .typeError("数値のみ有効です。")
          .min(1, "1以上の値を指定してください。")
          .max(50, "50以下の値を指定してください。")
          .required("同時稼働AI数は必須です。"),
        // processName: yup
        //   .string()
        //   .required("プロセス名は必須です。")
        //   .max(20, "プロセス名は大文字で10文字以下に設定してください。")
      }),
    [rowCount, selectedUsers]
  );

  const {
    handleSubmit,
    formState: { isValid, errors },
    control,
    reset,
  } = useForm({
    mode: "all",
    criteriaMode: "all",
    defaultValues: {
      callAiNum: 0,
      callNum: 0,
      callRangeMax: "0",
    },
    shouldFocusError: false,
    resolver: yupResolver(schema),
  });

  const [formValues, setFormValues] = useState<FormInput>({
    callRangeMax: "0",
    callNum: 0,
    callAiNum: 0,
  });

  const handleFormChange = useCallback(async (key, value) => {
    if (key === "callRangeMax") {
      const parseResult = parseCallRangeString(value);
      if (!parseResult) {
        setFormValues((prevValues) => ({
          ...prevValues,
          [key]: value,
        }));
        return;
      }
      const { min, max, selection } = parseResult;
      setcallStartPoint(min);
      setCallEndPoint(max);
      setRowSelection(selection);
      setCallRange(selection.length);
      setFormValues((prevValues) => ({
        ...prevValues,
        [key]: value,
        callNum: selection.length,
      }));
    } else {
      // コール範囲以外のフォームフィールドの変更の場合
      setFormValues((prevValues) => ({
        ...prevValues,
        [key]: value,
      }));
    }
  }, []);

  const forms = useMemo(
    () =>
      [
        {
          key: "callRangeMax",
          name: `コール範囲 (選択: ${formValues.callNum}件)`,
          disabled: !callListId,
        },
        { key: "callAiNum", name: "同時稼働AI数", disabled: false },
      ] as const,
    [callListId, formValues.callNum]
  );

  // 待機ユーザーの選択
  const companyUsers = useCompanyUsers();
  const waitingUsers = useMemo(
    () =>
      Object.values(companyUsers).filter((user) => {
        // user.role === "USER" &&
        return (
          user.online &&
          (companyUsers[user.id]?.status === "FREE" ||
            (companyUsers[user.id]?.status === "IN_CALL_WRAP_UP" &&
              companyUsers[user.id]?.assignedCallBatchIds.length === 0))
        );
      }),
    [companyUsers]
  );
  const theme = useTheme();
  const handleUserSelectChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    setSelectedUsers(typeof value === "string" ? value.split(",") : value);
    if (
      selectedUsers.length >
      maxAssigneesForAllCallBatches - CallBatchAssignees.length
    ) {
      setSelectedUsersValidationError(
        `ご利用のプランで同時に着席いただける人数は最大${maxAssigneesForAllCallBatches}人までです。`
      );
    } else {
      setSelectedUsersValidationError("");
    }
  };
  const getMenuItemStyles = (
    name: string,
    selectedUsers: readonly string[],
    theme: Theme
  ) => {
    return {
      fontWeight:
        selectedUsers.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  };

  const handleOnValidSubmit: SubmitHandler<FormInput> = async (data) => {
    if (!user.online) return;

    dispatch(setLoadingBackdrop({ key: "AICallCreateProcess", state: true }));
    const json = JSON.stringify({
      callStartPoint,
      callRange,
      processName,
      companyId: user.tenantId,
      uids: selectedUsers,
      scriptId,
      callListId,
      callAiNum: data.callAiNum,
    });

    setProcessName("");
    setScriptId("");
    setCallListId("");
    setPhoneNumber("");
    setRowSelection([]);
    reset({
      callAiNum: 0,
      callNum: 0,
      callRangeMax: "0",
    });
    setFormValues({
      callAiNum: 0,
      callNum: 0,
      callRangeMax: "0",
    });
    setSelectedUsers([]);

    try {
      console.log(json);
      const contactIdsRes = await request({
        path: "/contact_lists/{listId}/contacts/ids",
        httpMethod: "get",
        params: {
          paths: {
            listId: callListId,
          },
        },
      });
      if (
        contactIdsRes.result !== "success" ||
        contactIdsRes.data === undefined
      ) {
        dispatch(
          setSnackbar({
            text: "コールリストの取得に失敗しました。",
            open: true,
            severity: "error",
          })
        );
        dispatch(
          setLoadingBackdrop({ key: "AICallCreateProcess", state: false })
        );
        return;
      }
      const allContactIds = contactIdsRes.data.ids;

      const contactIds = allContactIds.filter(
        (_, i) => i >= callStartPoint - 1 && i <= callEndPoint - 1
      );

      const res = await request({
        path: "/call_batches",
        httpMethod: "post",
        params: {
          body: {
            name: processName,
            scriptId,
            contactIds,
            from: phoneNumber,
            maxConcurrency: data.callAiNum,
            assigneeIds: selectedUsers,
          },
        },
      });
      console.log({ res });
      console.log(res.data.status);
      dispatch(
        setLoadingBackdrop({ key: "AICallCreateProcess", state: false })
      );
      await fetchCallBatchAssignees();
      dispatch(
        setSnackbar({
          text: "正常にコールプロセスが作成されました。",
          open: true,
          severity: "success",
        })
      );
    } catch (e) {
      console.error(e);
      dispatch(
        setSnackbar({
          text: "コールプロセスの作成に失敗しました。",
          open: true,
          severity: "error",
        })
      );
      dispatch(
        setLoadingBackdrop({ key: "AICallCreateProcess", state: false })
      );
    }
  };

  //コールバッチの割り当てユーザーを取得
  async function fetchCallBatchAssignees() {
    const res = await request({
      path: "/call_batches/actives",
      httpMethod: "get",
    });
    console.log("fetchCallBatchAssignees", res);
    if (res.result === "success") {
      const assignees: string[] = [];
      res.data.callBatches.forEach((batch) => {
        batch.assigneeIds.forEach((assignee) => {
          assignees.push(assignee);
        });
      });
      console.log("fetchCallBatchAssignees", assignees);
      setCallBatchAssignees(assignees);
    }
  }

  // 全体の最大割り当て数を取得
  async function fetchMaxAssigneesForAllCallBatches() {
    const res = await request({
      path: "/settings",
      httpMethod: "get",
    });
    console.log("fetchMaxAssigneesForAllCallBatches", res);
    if (
      res.result === "success" &&
      res.data.maxAssigneesForAllCallBatches >= 0
    ) {
      setMaxAssigneesForAllCallBatches(res.data.maxAssigneesForAllCallBatches);
    }
  }

  useEffect(() => {
    if (callListId) {
      request({
        path: "/contact_lists/{listId}/contacts",
        httpMethod: "get",
        params: {
          paths: {
            listId: callListId,
          },
          query: {
            limit: 1,
            offset: 0,
            callNoteResults: ["NEVER_CALLED"],
          },
        },
      })
        .then((res) => {
          if (res.result === "success" && res.data.contacts.length) {
            console.log("NEVER_CALLED item found");
            setNeverCalledItemIndex(
              res.data.contacts[0].contactListSequence + 1
            );
          } else {
            console.log("NEVER_CALLED item not found");
            setNeverCalledItemIndex(null);
          }
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [callListId]);

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Box sx={{ mb: 2 }}>
        <DSBreadcrumbs breadcrumbRefs={breadcrumbRefs}></DSBreadcrumbs>
      </Box>
      {
        // ロールがフェッチされるまではスケルトンを表示しておく
        !user.permissions.includes("callBatches.create") && (
          <Skeleton height={600} sx={{ p: 4, transform: "none" }}></Skeleton>
        )
      }
      {user.permissions.includes("callBatches.create") && (
        <Stack direction="row" gap={3} justifyContent="center">
          <form onSubmit={handleSubmit(handleOnValidSubmit)}>
            <Stack
              alignContent="center"
              justifyContent="center"
              flexWrap="wrap"
              gap={2}
              maxWidth={500}
              mx="auto"
            >
              <Paper sx={{ overflow: "hidden", maxWidth: 500 }}>
                <Box
                  display="flex"
                  alignContent="center"
                  bgcolor="#f3f3f3"
                  sx={{ p: 2 }}
                >
                  <CallOutlined sx={{ mr: 1 }}></CallOutlined>
                  <Typography textAlign="left">コール設定</Typography>
                </Box>

                <Stack
                  alignContent="center"
                  flexWrap="wrap"
                  gap={2}
                  sx={{ p: 3 }}
                >
                  <FormControl size="small" fullWidth sx={{ mx: "auto" }}>
                    <InputLabel shrink sx={{ bgcolor: "#fff", px: 0.5 }}>
                      プロセス名
                    </InputLabel>
                    <TextField
                      size="small"
                      value={processName}
                      onChange={(e) => setProcessName(e.target.value)}
                    ></TextField>
                  </FormControl>

                  <FormControl size="small" fullWidth sx={{ mx: "auto" }}>
                    <InputLabel shrink sx={{ bgcolor: "#fff", px: 0.5 }}>
                      <ReceiptLongOutlined
                        sx={{ mb: -0.75, mr: 0.5 }}
                      ></ReceiptLongOutlined>
                      スクリプト
                    </InputLabel>
                    <Select
                      size="small"
                      value={scriptId}
                      onChange={(e) => setScriptId(e.target.value)}
                    >
                      {Object.entries(scripts).map(([id, script]) => (
                        <MenuItem value={id} key={id}>
                          {script.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl size="small" fullWidth sx={{ mx: "auto" }}>
                    <InputLabel shrink sx={{ bgcolor: "#fff", px: 0.5 }}>
                      <ContactPhoneOutlined
                        sx={{ mb: -0.75, mr: 0.5 }}
                      ></ContactPhoneOutlined>
                      コールリスト
                    </InputLabel>
                    <Select
                      size="small"
                      value={callListId}
                      onChange={(e) => setCallListId(e.target.value)}
                    >
                      {Object.values(callListMetadata).map((metadata) => (
                        <MenuItem value={metadata.id} key={metadata.id}>
                          <Typography
                            minWidth={400}
                            maxWidth={400}
                            mr={1}
                            textOverflow="ellipsis"
                            overflow="hidden"
                          >
                            {metadata.name}
                          </Typography>
                          <Typography>
                            (更新日: {formatTimestamp(metadata.updatedAt)})
                          </Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <Box display="flex" gap={2}>
                    {forms.map((form) => (
                      <Controller
                        key={form.key}
                        name={form.key}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            size="small"
                            label={form.name}
                            error={form.key in errors}
                            disabled={form.disabled}
                            helperText={errors[form.key]?.message || ""}
                            value={formValues[form.key]} // ここでフォームの値を表示
                            onChange={(e) => {
                              const newValue = e.target.value;
                              handleFormChange(form.key, newValue);
                              field.onChange(newValue);
                            }}
                          />
                        )}
                      />
                    ))}
                  </Box>

                  <FormControl fullWidth size="small">
                    <InputLabel shrink sx={{ bgcolor: "#fff", px: 0.5 }}>
                      <LocalPhoneOutlined
                        sx={{ mb: -0.75, mr: 0.5 }}
                      ></LocalPhoneOutlined>
                      発信番号
                    </InputLabel>
                    <Select
                      size="small"
                      labelId="user"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    >
                      {companyPhoneNumbers?.map((phoneNumber) => (
                        <MenuItem value={phoneNumber} key={phoneNumber}>
                          {phoneNumber.replace(/^\+81/, "0")}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  {/* <Box display="flex" alignContent="center" mt={4}>
                    <PersonOutlined sx={{ mr: 1 }}></PersonOutlined>
                    <Typography textAlign="left">
                      割り当てユーザー
                    </Typography>
                  </Box> */}
                  <FormControl
                    sx={{ mx: "auto", mt: 2 }}
                    fullWidth
                    size="small"
                  >
                    <InputLabel shrink sx={{ bgcolor: "#fff", px: 0.5 }}>
                      <PersonAddOutlined
                        sx={{ mb: -0.75, mr: 0.5 }}
                      ></PersonAddOutlined>
                      割り当てユーザー
                    </InputLabel>
                    <Select
                      multiple
                      size="small"
                      labelId="user"
                      value={selectedUsers}
                      onChange={handleUserSelectChange}
                      sx={{ minHeight: 100 }}
                      renderValue={(selected) => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {selected.map((value) => (
                            <Chip
                              key={value}
                              label={companyUsers[value]?.name}
                            />
                          ))}
                        </Box>
                      )}
                    >
                      {waitingUsers.map((user) => (
                        <MenuItem
                          key={user.name}
                          value={user.id}
                          style={getMenuItemStyles(
                            user.name,
                            selectedUsers,
                            theme
                          )}
                        >
                          {user.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {selectedUsersValidationError && (
                    <Typography color="error" variant="body2" sx={{ mt: 0 }}>
                      {selectedUsersValidationError}
                    </Typography>
                  )}
                </Stack>
              </Paper>

              <Button
                size="large"
                sx={{ maxWidth: 300, mt: 4, mx: "auto" }}
                startIcon={<CallOutlined />}
                type="submit"
                variant="contained"
                color="primary"
                disabled={
                  !user.permissions.includes("callBatches.create") ||
                  !processName ||
                  !callListId ||
                  !scriptId ||
                  !phoneNumber ||
                  !isValid ||
                  !selectedUsers.length ||
                  selectedUsers.length >
                    maxAssigneesForAllCallBatches - CallBatchAssignees.length
                }
              >
                コール開始
              </Button>
            </Stack>
          </form>

          <Stack gap={1} maxWidth={380}>
            <Paper sx={{ opacity: callListId ? 1 : 0.4 }}>
              {/* <Typography color="#999" fontSize="0.85rem">
                コールリスト詳細
              </Typography> */}
              <Box sx={{ height: 500, minHeight: 500, position: "relative" }}>
                <CallListTable
                  callListId={callListId}
                  callListItems={callListItems}
                  rowSelectionModel={rowSelection}
                  onRowSelectionModelChange={(model) => setRowSelection(model)}
                  showCallResults={false}
                  showCallAICallPage={true}
                  editMode={false}
                  checkboxSelection
                  handleChangePage={(paginationModel) => {
                    handleChangePage(null, paginationModel);
                  }}
                  hideCheckbox
                  handleChangeSort={handleChangeSort}
                  rowCount={rowCount}
                  pageSize={100}
                  autoFocusNeverCalled={true}
                  neverCalledItemIndex={neverCalledItemIndex}
                ></CallListTable>
                <RelativeBackdrop open={fetching}>
                  <CircularProgress sx={{ mx: "auto" }}></CircularProgress>
                </RelativeBackdrop>
              </Box>
            </Paper>
          </Stack>
        </Stack>
      )}
    </Container>
  );
};

export default Call;
