import ringingAudioFilePath from "@/assets/ringing.mp3";
import { sendCallResult } from "@/features/callResultRegister/sendCallResult";
import { CallPad } from "@/features/CallScreen/CallScreenCallPad";
import {
  CallScreenResultRegister,
  Result,
} from "@/features/CallScreen/CallScreenResultRegister";
import { request } from "@/models/telai-backend/client";
import { paths } from "@/models/telai-backend/schema";
import { setTwilioCall } from "@/store/callSlice";
import { setLoadingBackdrop, setSnackbar } from "@/store/commonSlice";
import { AppDispatch, RootState } from "@/store/store";
import {
  getCurrentUser,
  setMissedManualInboundCallOn,
} from "@/store/userSlice";
import isValidURL from "@/utils/isValidURL";
import {
  Box,
  Button,
  Divider,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

const CallScreen = () => {
  const [callResultRegister, setCallResultRegister] = useState(false);
  const [callIsAccepted, setCallIsAccepted] = useState(false);
  const audioElementRef = useRef<HTMLAudioElement | null>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { tenantId } = useParams();

  const twilioCall = useSelector((state: RootState) => state.call.twilioCall);
  const [callResultRegistered, setCallResultRegistered] = useState(false);
  const callNoteResults = useSelector(
    (state: RootState) => state.company.config.callNoteResults
  );
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);

  const [user, setUser] = useState<
    | paths["/users/me"]["get"]["responses"]["200"]["content"]["application/json"]
    | undefined
  >(undefined);
  const [currentCall, setCurrentCall] = useState<
    | paths["/calls/{callId}"]["get"]["responses"]["200"]["content"]["application/json"]
    | undefined
  >(undefined);
  const [previousCall, setPreviousCall] = useState<
    | paths["/calls/{callId}"]["get"]["responses"]["200"]["content"]["application/json"]
    | undefined
  >(undefined);
  const [transcript, setTranscript] = useState<
    | paths["/calls/{callId}/transcriptions"]["get"]["responses"]["200"]["content"]["application/json"]
    | undefined
  >(undefined);
  const [contact, setContact] = useState<
    | paths["/contacts/{contactId}"]["get"]["responses"]["200"]["content"]["application/json"]
    | undefined
  >(undefined);
  const [contactList, setContactList] = useState<
    | paths["/contact_lists/{listId}"]["get"]["responses"]["200"]["content"]["application/json"]
    | undefined
  >(undefined);

  // ユーザー情報の取得
  useEffect(() => {
    (async () => {
      if (user !== null && user !== undefined) return;

      const userRes = await request({
        path: "/users/me",
        httpMethod: "get",
      });
      if (userRes.result === "error") {
        console.error(userRes.error);
        return;
      }
      setUser(userRes.data);
    })();
  }, [dispatch, user]);

  // 現在コール情報の取得
  useEffect(() => {
    (async () => {
      if (user === null || user === undefined) {
        dispatch(getCurrentUser());
        return;
      }

      let callId: string | undefined = undefined;
      if (user.assignedCallIds.length > 0) {
        callId = user.assignedCallIds[0];
      } else if (user.unfilledNoteResultCallIds.length > 0) {
        callId = user.unfilledNoteResultCallIds[0];
      }

      if (!callId) {
        console.log("callId not found");
        return;
      }

      const callRes = await request({
        path: "/calls/{callId}",
        httpMethod: "get",
        params: {
          paths: { callId },
        },
      });
      if (callRes.result === "error") {
        console.error(callRes.error);
        return;
      }
      setCurrentCall(callRes.data);
    })();
  }, [dispatch, user]);

  // 前回コール情報の取得
  useEffect(() => {
    (async () => {
      if (currentCall === undefined || currentCall === null) {
        console.log({
          message: "currentCall が取得できませんでした",
          currentCall,
        });
        return;
      }

      const contactId =
        (currentCall.direction === "INBOUND" && currentCall.fromContactId) ||
        (currentCall.direction === "OUTBOUND" && currentCall.toContactId);

      console.log("fetch====>>", contactId);
      if (contactId) {
        const contactRes = await request({
          path: "/calls",
          httpMethod: "get",
          params: {
            query: {
              contactId,
              limit: 1,
              offset: 1,
            },
          },
        });
        if (contactRes.result === "error") {
          console.error(contactRes.error);
          return;
        }

        let previousCallId: string | undefined = undefined;
        contactRes.data.calls.forEach((call) => {
          if (call.id === currentCall.id) return;
          previousCallId = call.id;
        });

        if (!previousCallId) return;

        const callRes = await request({
          path: "/calls/{callId}",
          httpMethod: "get",
          params: {
            paths: { callId: previousCallId },
          },
        });
        if (callRes.result === "error") {
          console.error(callRes.error);
          return;
        }
        setPreviousCall(callRes.data);
      } else {
        //電話番号からコンタクトを取得
        const contact = await request({
          path: "/calls",
          httpMethod: "get",
          params: {
            query: {
              limit: 2,
              offset: 0,
              phoneNumber: currentCall.to,
            },
          },
        });
        console.log("contact====>>", contact);
        if (contact.result === "error" || contact.data.calls.length < 2) {
          console.log("contact not found");
          return;
        }
        setPreviousCall(contact.data.calls[1]);
      }
    })();
  }, [currentCall]);

  // 連絡先情報の取得
  useEffect(() => {
    (async () => {
      if (currentCall === undefined || currentCall === null) {
        console.log({
          message: "currentCall が取得できませんでした",
          currentCall,
        });
        return;
      }

      const contactId =
        (currentCall.direction === "INBOUND" && currentCall.fromContactId) ||
        (currentCall.direction === "OUTBOUND" && currentCall.toContactId);

      if (!contactId) return;

      const contactRes = await request({
        path: "/contacts/{contactId}",
        httpMethod: "get",
        params: {
          paths: { contactId },
        },
      });
      if (contactRes.result === "error") {
        console.error(contactRes.error);
        return;
      }
      setContact(contactRes.data);

      if (contactRes.data.contactListId) {
        const contactListRes = await request({
          path: "/contact_lists/{listId}",
          httpMethod: "get",
          params: {
            paths: { listId: contactRes.data.contactListId },
          },
        });
        if (contactListRes.result === "error") {
          console.error(contactListRes.error);
          return;
        }
        setContactList(contactListRes.data);
      }
    })();
  }, [currentCall]);

  // 会話ログの取得
  useEffect(() => {
    (async () => {
      if (currentCall === undefined || currentCall === null) {
        console.log({
          message: "currentCall が取得できませんでした",
          currentCall,
        });
        return;
      }

      const transcriptRes = await request({
        path: "/calls/{callId}/transcriptions",
        httpMethod: "get",
        params: {
          paths: { callId: currentCall.id },
        },
      });
      if (transcriptRes.result === "error") {
        console.error(transcriptRes.error);
        return;
      }
      setTranscript(transcriptRes.data);
    })();
  }, [currentCall]);

  // メモにURLが含まれている場合にはURLを入力
  const contactListMetadataURL: string | undefined = useMemo(() => {
    if (contact && contactList) {
      const urlMetadataKey = contactList.metadataKeys.find((key) => {
        const value = contact.metadata[key];
        return isValidURL(value);
      });
      if (urlMetadataKey) {
        return contact.metadata[urlMetadataKey];
      }
    }
    return undefined;
  }, [contact, contactList]);

  // 検索クエリの生成
  useEffect(() => {
    if (
      contact !== null &&
      contact !== undefined &&
      contactList !== null &&
      contactList !== undefined
    ) {
      setSearchQuery(
        contact.name.replace(/\s+/g, "+") + "+" + contactList.description
      );
    }
  }, [contact, contactList]);

  const displayURL = useMemo(() => {
    if (!searchQuery) return null;
    return (
      contactListMetadataURL ||
      encodeURI(`https://www.google.com/search?q=${searchQuery}&igu=1`)
    );
  }, [searchQuery, contactListMetadataURL]);

  const Iframe = useMemo(() => {
    console.log({ searchQuery });

    if (searchQuery === null) {
      return () => (
        <Stack
          width="100%"
          height="100%"
          justifyContent="center"
          alignItems="center"
        >
          <Typography sx={{ opacity: 0.4 }}>(コール先情報なし)</Typography>
        </Stack>
      );
    }

    return () => (
      <Box width="100%" height="100%" display="flex" flexDirection="column">
        <Box
          sx={{ bgcolor: "#e5e5e5", p: 2 }}
          display="flex"
          alignItems="center"
          flexDirection="column"
          borderBottom="1px solid #e0e0e0"
        >
          <Button
            variant="contained"
            color="primary"
            href={displayURL}
            target="_blank"
            rel="noreferrer noopener"
            sx={{ m: 2, textTransform: "none" }}
          >
            {contactListMetadataURL || `Google検索: ${searchQuery}`}
          </Button>
          <Typography textAlign="center">
            (reCAPTCHAが表示される場合はボタンを押すと検索できます)
          </Typography>
        </Box>
        <iframe width="100%" height="100%" src={displayURL}></iframe>
      </Box>
    );
  }, [contactListMetadataURL, displayURL, searchQuery]);

  const handleEndCall = () => {
    setCallResultRegister(true);
  };

  // コール結果の登録
  const handleResultSubmit = useCallback(
    async (submit: Result) => {
      if (!submit.callResult) throw new Error("empty call result");

      try {
        await sendCallResult({
          callId: currentCall.id,
          callResult: submit.callResult,
          nextCallDate: submit.nextCallDate,
          callMemo: submit.callMemo,
        });
        setCallResultRegistered(true);
      } catch (e) {
        console.error(e);
        dispatch(
          setSnackbar({
            text: "コール結果の登録に失敗しました。ホームへ戻ります。",
            severity: "error",
            open: true,
          })
        );
      } finally {
        dispatch(setTwilioCall(null));
        navigate(`/${tenantId}/`);
      }
    },
    [currentCall, dispatch, navigate, tenantId]
  );

  // コール結果登録後にユーザーの状態がIN_CALL_WRAP_UPでなくなったらホーム画面へ遷移
  // (IN_CALL_WRAP_UPの状態でホーム画面へ遷移すると/callscreenへ戻されてしまうため)
  useEffect(() => {
    if (!callResultRegistered) return;
    if (!user) return;
    if (["IN_CALL", "IN_CALL_WRAP_UP"].includes(user.status)) return;
    dispatch(
      setSnackbar({
        text: "正常にコール結果を登録しました。ホームへ戻ります。",
        severity: "success",
        open: true,
      })
    );
    navigate(`/${tenantId}/`);
    setCallResultRegistered(false);
    dispatch(setLoadingBackdrop({ key: "callResultSubmit", state: false }));
  }, [callResultRegistered, dispatch, navigate, tenantId, user]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (currentCall !== undefined && currentCall !== null) {
        return;
      }
      dispatch(getCurrentUser());
    }, 1000);

    // クリーンアップ処理
    return () => clearInterval(intervalId);
  }, [currentCall, dispatch]);

  // コール先情報のフェッチ, コールバックの登録
  useEffect(() => {
    if (!twilioCall) return;

    console.log(twilioCall?.customParameters);
    console.log(twilioCall?.outboundConnectionId);
    console.log("incoming callSid:" + twilioCall?.parameters?.CallSid);

    twilioCall.on("connection", () => {
      console.log("call connected");
    });

    twilioCall.on("cancel", () => {
      console.error("call canceled");
      // callInstance.disconnect()
      dispatch(setTwilioCall(null));
      setCallResultRegister(true);
    });

    twilioCall.on("error", () => {
      console.error("call error");
      // callInstance.disconnect()
      dispatch(setTwilioCall(null));
      setCallResultRegister(true);
    });

    twilioCall.on("disconnected", () => {
      console.log("call disconnected");
      dispatch(setTwilioCall(null));
      setCallResultRegister(true);
    });
  }, [dispatch, twilioCall]);

  // 通話が COMPLETED になったら自動で結果登録画面へ遷移
  useEffect(() => {
    const status = currentCall?.status;

    if (status === "COMPLETED") {
      if (twilioCall) {
        console.log("disconnecting call because status is COMPLETED");
        twilioCall.disconnect();
      }
      setCallResultRegister(true);
      if (audioElementRef.current) audioElementRef.current.pause();
    }

    console.log(currentCall?.direction, status, callIsAccepted);

    // 手動受電時に通話に出れなかった場合、
    // 結果登録画面をスキップしてホーム画面へ遷移し通知を表示
    if (
      currentCall?.direction === "INBOUND" &&
      status === "COMPLETED" &&
      !callIsAccepted
    ) {
      dispatch(setLoadingBackdrop({ key: "callResultSubmit", state: true }));
      sendCallResult({
        callId: currentCall?.id,
        callResult: "MANUAL_INBOUND_UNREACHABLE",
      }).then(() => {
        dispatch(setLoadingBackdrop({ key: "callResultSubmit", state: false }));
        dispatch(setMissedManualInboundCallOn(true));
      });
    } else {
      dispatch(setMissedManualInboundCallOn(false));
    }
  }, [callIsAccepted, currentCall, dispatch, twilioCall]);

  /**
   * URLかどうかを判定
   * @param {string} string - 判定対象の文字列
   * @returns {boolean} URLかどうか
   */
  const isUrl = (string) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  // トスアップ通知用音声の再生
  useEffect(() => {
    const ring = async () => {
      if (!twilioCall || twilioCall.status() === "closed") return;
      if (audioElementRef.current && !audioElementRef.current.paused) return;

      try {
        const audio = new Audio(ringingAudioFilePath);
        audio.loop = true;
        audio.play();
        audioElementRef.current = audio;
      } catch (e) {
        console.error(e);
        return null;
      }
    };
    ring();
  }, [twilioCall]);

  const handleOnUnmute = useCallback(() => {
    setCallIsAccepted(true);
    if (audioElementRef.current) audioElementRef.current.pause();
  }, []);

  return (
    <Box display="flex" height="100vh" width="100%" overflow="hidden">
      <Box width={380}>
        <Stack height="100%">
          <>
            {callResultRegister ? (
              <CallScreenResultRegister
                callDuration="00:00"
                onSubmit={handleResultSubmit}
                manualCalling={currentCall?.batchId === null}
                direction={
                  currentCall?.direction === "INBOUND" ? "INCOMING" : "OUTGOING"
                }
              ></CallScreenResultRegister>
            ) : (
              <CallPad
                call={currentCall}
                onEndCall={handleEndCall}
                onUnmute={handleOnUnmute}
                displayName={contact?.name}
              ></CallPad>
            )}
            <Paper
              sx={{
                bgcolor: "#454545",
                color: "#fff",
                borderRadius: 0,
                p: 4,
                height: 660,
                maxHeight: 660,
                overflowY: "scroll",
                scrollbarColor: "#666666 #454545",
              }}
            >
              <Stack>
                <Typography sx={{ pb: 1, px: 1 }} textAlign="left">
                  基本情報
                </Typography>
                <Table
                  size="small"
                  sx={{
                    "& .MuiTableCell-root": {
                      borderBottom: "none",
                      color: "#fff",
                      fontSize: "0.8rem",
                      padding: "2px 12px",
                    },
                  }}
                >
                  <TableBody>
                    {(!contact || !Object.keys(contact).length) && (
                      <TableRow>
                        <TableCell
                          colSpan={2}
                          sx={{ textAlign: "center", height: "auto", px: 0 }}
                        >
                          <Typography
                            sx={{
                              opacity: 0.5,
                              fontSize: "0.85rem",
                              bgcolor: "rgba(255,255,255,0.1)",
                              p: 4,
                            }}
                          >
                            コールリスト情報なし
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    {contact && (
                      <TableRow>
                        <TableCell>会社名</TableCell>
                        <TableCell>
                          {isUrl(contact?.name) ? (
                            <a
                              style={{
                                color: "white",
                                textDecoration: "none",
                              }}
                              onMouseOver={(e) =>
                                (e.currentTarget.style.color = "white")
                              }
                              onMouseOut={(e) =>
                                (e.currentTarget.style.color = "white")
                              }
                              onFocus={(e) =>
                                (e.currentTarget.style.color = "white")
                              }
                              onBlur={(e) =>
                                (e.currentTarget.style.color = "white")
                              }
                              href={contact.name}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {contact.name}
                            </a>
                          ) : (
                            contact?.name || "─"
                          )}
                        </TableCell>
                      </TableRow>
                    )}
                    {contactList &&
                      contactList.metadataKeys.slice(0, 7).map((label) => (
                        <TableRow key={label}>
                          <TableCell>{label}</TableCell>
                          <TableCell
                            sx={{
                              textAlign: !contact?.metadata?.[label]
                                ? "center"
                                : "left",
                            }}
                          >
                            {isUrl(contact?.metadata?.[label]) ? (
                              <a
                                style={{
                                  color: "white",
                                  textDecoration: "none",
                                }}
                                onMouseOver={(e) =>
                                  (e.currentTarget.style.color = "white")
                                }
                                onMouseOut={(e) =>
                                  (e.currentTarget.style.color = "white")
                                }
                                onFocus={(e) =>
                                  (e.currentTarget.style.color = "white")
                                }
                                onBlur={(e) =>
                                  (e.currentTarget.style.color = "white")
                                }
                                href={contact.metadata[label]}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {contact.metadata[label]}
                              </a>
                            ) : (
                              contact?.metadata?.[label] || "─"
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Stack>

              <Divider
                sx={{ borderColor: "#fff", opacity: "0.2", my: 4 }}
              ></Divider>

              <Stack gap={1.5} px={1}>
                <Typography textAlign="left">会話ログ</Typography>
                {transcript && transcript.transcriptions ? (
                  transcript.transcriptions.map(
                    ({ speaker, content }, index) => (
                      <Stack
                        pl={speaker === "TO" ? 0 : 4}
                        pr={speaker === "TO" ? 4 : 0}
                        key={index}
                      >
                        <Stack
                          key={index}
                          direction="row"
                          display="inline-flex"
                          gap={1}
                          bgcolor="rgba(255,255,255,0.1)"
                          ml={speaker === "TO" ? 0 : "auto"}
                          mr={speaker === "TO" ? "auto" : 0}
                          py={0.5}
                          px={1}
                        >
                          <Typography
                            fontSize="0.7rem"
                            sx={{ opacity: 0.6 }}
                            textAlign={speaker === "TO" ? "left" : "right"}
                            order={speaker === "TO" ? 0 : 1}
                            whiteSpace="nowrap"
                          >
                            {speaker === "TO" ? "顧客" : "AI"}
                          </Typography>
                          <Typography
                            fontSize="0.8rem"
                            textAlign="left"
                            lineHeight="1.2"
                          >
                            {content}
                          </Typography>
                        </Stack>
                      </Stack>
                    )
                  )
                ) : (
                  <Box bgcolor="rgba(255,255,255,0.05)" p={4}>
                    <Typography fontSize="0.85rem" sx={{ opacity: "0.5" }}>
                      会話ログなし
                    </Typography>
                  </Box>
                )}
              </Stack>

              <Divider
                sx={{ borderColor: "#fff", opacity: "0.2", my: 4 }}
              ></Divider>

              <Stack>
                <Typography sx={{ pb: 1, px: 1 }} textAlign="left">
                  前回コール
                </Typography>
                <Table
                  size="small"
                  sx={{
                    "& .MuiTableCell-root": {
                      borderBottom: "none",
                      color: "#fff",
                      fontSize: "0.8rem",
                      padding: "2px 12px",
                    },
                  }}
                >
                  <TableBody>
                    {previousCall ? (
                      <TableRow>
                        <TableCell
                          colSpan={2}
                          sx={{
                            textAlign: "left",
                            height: "auto",
                            width: "auto",
                            px: 0,
                          }}
                        >
                          <Typography sx={{ fontSize: "0.85rem", p: 0 }}>
                            {/* 通話ID: {previousCall.id}<br /> */}
                            通話日時:{" "}
                            {previousCall.createdAt
                              ? dayjs(previousCall.createdAt).format(
                                  "YYYY/MM/DD HH:mm"
                                )
                              : ""}
                            <br />
                            コール結果:{" "}
                            {callNoteResults[previousCall.noteResult]?.name ||
                              previousCall.noteResult}
                            <br />
                            コールメモ: {previousCall.noteContent || "なし"}
                            <br />
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow>
                        <TableCell
                          colSpan={2}
                          sx={{ textAlign: "center", height: "auto", px: 0 }}
                        >
                          <Typography
                            sx={{
                              opacity: 0.5,
                              fontSize: "0.85rem",
                              bgcolor: "rgba(255,255,255,0.1)",
                              p: 4,
                            }}
                          >
                            前回コール情報なし
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Stack>
            </Paper>
          </>
        </Stack>
      </Box>
      <Box flexGrow={1}>
        <Iframe />
      </Box>
    </Box>
  );
};

export default CallScreen;
