import { CallHistoryRecord, CallDirection } from "@/models/CallHistory";
import { Contact, ContactList } from "@/models/Contact";
import { request } from "@/models/telai-backend/client";
import { Dayjs } from "dayjs";

const DEFAULT_QUERY_LIMIT = 50;

type CallHistoryFilter = {
  startDate?: Dayjs | null;
  callHistoryId?: string;
  nextCallStartDate?: Dayjs | null;
  nextCallEndDate?: Dayjs | null;
  minCallDuration?: number;
  endDate?: Dayjs | null;
  uid?: string[];
  callResult?: string[];
  callListId?: string[];
  scriptId?: string[];
  keyword?: string;
  phoneNumber?: string;
};

// ページネーション用のクラス
class CallHistoryCursor {
  readonly filter: CallHistoryFilter;
  readonly callsPerPage: number;
  private offset?: number;

  constructor({
    filter,
    callsPerPage,
  }: {
    filter: CallHistoryFilter;
    callsPerPage?: number;
  }) {
    this.filter = filter;
    this.callsPerPage = callsPerPage || DEFAULT_QUERY_LIMIT;
  }

  private queryPhoneNumber = (phoneNumber: string): string => {
    // 電話番号の形式を変換 最初の0を+81に変換
    return phoneNumber.replace(/^0/, "+81");
  };

  fetchNext = async (
    count: number = 1
  ): Promise<{
    total: number;
    records: { [historyId: string]: CallHistoryRecord };
  }> => {
    const filter = this.filter;

    const queryPhoneNumber = this.queryPhoneNumber(filter.phoneNumber);

    console.log("filter", filter);
    const res = await request({
      path: "/calls",
      httpMethod: "get",
      params: {
        query: {
          limit: this.callsPerPage || undefined,
          offset: (count - 1) * this.callsPerPage,
          includeQueued: false,
          createdAtStart: filter.startDate
            ? filter.startDate.startOf("day").toISOString()
            : undefined,
          createdAtEnd: filter.endDate
            ? filter.endDate.endOf("day").toISOString()
            : undefined,
          noteResult:
            filter.callResult.length === 0 ? undefined : filter.callResult,
          contactListId:
            filter.callListId.length === 0 ? undefined : filter.callListId,
          scriptId: filter.scriptId.length === 0 ? undefined : filter.scriptId,
          durationMin: filter.minCallDuration || undefined,
          durationMax: undefined,
          assigneeId: filter.uid.length === 0 ? undefined : filter.uid,
          status: undefined,
          nextCallScheduledAtStart: filter.nextCallStartDate
            ? filter.nextCallStartDate.startOf("day").toISOString()
            : undefined,
          nextCallScheduledAtEnd: filter.nextCallEndDate
            ? filter.nextCallEndDate.endOf("day").toISOString()
            : undefined,
          phoneNumber: queryPhoneNumber || undefined,
          query: filter.keyword || undefined,
        },
      },
    });

    console.log("fetchNextが実行", res);
    if (res.result === "error") return { total: 0, records: {} };

    console.log("res.data.calls: ", res.data.calls);

    const checkCallHistoryData = (
      data: "OUTBOUND" | "INBOUND"
    ): CallDirection => {
      return data === "OUTBOUND" ? "OUTGOING" : "INCOMING";
    };

    const checkResult = (data: string): string => {
      if (!data) {
        return "UNREACHABLE";
      }
      return data;
    };

    const callHistoriesData: CallHistoryRecord[] = res.data.calls.map(
      (call) => {
        return {
          id: call.id,
          companyName:
            (call.direction === "INBOUND"
              ? call.fromContactName
              : call.toContactName) || "(不明)",
          createdAt: call.createdAt,
          updatedAt: call.updatedAt,
          calledAt: call.calledAt,
          callDirection: checkCallHistoryData(call.direction),
          callDuration: call.duration,
          callMemo: "",
          result: checkResult(call.noteResult),
          phoneNumber:
            (call.direction === "INBOUND" ? call.from : call.to) || "(不明)",
          audioPath: "",
          transcriptPath: "",
          operatorId: call.assigneeId,
          scriptId: call.scriptId,
          callListId: call.toContactId,
          toContactId: call.toContactId,
          fromContactId: call.fromContactId,
          callListIndex: 0,
          redialAttempts: 0,
          phoneCallId: call.phoneCallId,
          callTags: call.callTags,
        };
      }
    );

    const callHistories = callHistoriesData.map(
      (callHistoryData: CallHistoryRecord) => {
        return [callHistoryData.id, callHistoryData];
      }
    );

    return {
      total: res.data.total,
      records: Object.fromEntries(callHistories),
    };
  };

  getCallHistory = async (
    callHistoryId
  ): Promise<{ callHistoryData: CallHistoryRecord }> => {
    const res = await request({
      path: `/calls/{callId}`,
      httpMethod: "get",
      params: {
        paths: {
          callId: callHistoryId,
        },
      },
    });

    // 通話相手の情報を取得
    let contactRes: Contact | null;
    if (res.data.toContactId) {
      await request({
        path: `/contacts/{contactId}`,
        httpMethod: "get",
        params: {
          paths: {
            contactId: res.data.toContactId,
          },
        },
      })
        .then((res) => {
          contactRes = res.data;
        })
        .catch(() => {
          contactRes = null;
        });
    } else {
      contactRes = null;
    }

    let contactListRes: ContactList | null;
    if (contactRes && contactRes.contactListId) {
      // 通話相手のリスト情報を取得
      await request({
        path: `/contact_lists/{listId}`,
        httpMethod: "get",
        params: {
          paths: {
            listId: contactRes.contactListId,
          },
        },
      })
        .then((res) => {
          contactListRes = res.data;
        })
        .catch(() => {
          contactListRes = null;
        });
    } else {
      contactListRes = null;
    }

    const checkCallHistoryData = (
      data: "OUTBOUND" | "INBOUND"
    ): CallDirection => {
      return data === "OUTBOUND" ? "OUTGOING" : "INCOMING";
    };

    const checkResult = (data: string): string => {
      if (!data) {
        return "UNREACHABLE";
      }
      return data;
    };

    const callHistoryData: CallHistoryRecord = {
      id: res.data.id,
      companyName: res.data.toContactName || "",
      createdAt: res.data.createdAt,
      updatedAt: res.data.updatedAt,
      calledAt: res.data.calledAt,
      callDirection: checkCallHistoryData(res.data.direction),
      callDuration: res.data.duration,
      callMemo: res.data.noteContent,
      result: checkResult(res.data.noteResult),
      phoneNumber: res.data.to || "",
      audioPath: "",
      transcriptPath: "",
      operatorId: res.data.assigneeId,
      scriptId: res.data.scriptId,
      callListId: contactListRes ? contactListRes.id : "",
      toContactId: res.data.toContactId,
      fromContactId: res.data.fromContactId,
      callListIndex: 0,
      redialAttempts: 0,
      phoneCallId: res.data.phoneCallId,
      callTags: res.data.callTags,
    };

    console.log("callHistoryData", callHistoryData);

    return { callHistoryData };
  };
}

export { CallHistoryCursor };
export type { CallHistoryFilter };
