import { BreadcrumbRef, DSBreadcrumbs } from "@/components/DSBreadcrumbs";
import { request } from "@/models/telai-backend/client";
import { RootState } from "@/store/store";
import { Delete, Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Link,
  List,
  ListItem,
  Skeleton,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

const ROLE_DISPLAY_NAMES: { [key: string]: string } = {
  owner: "管理者",
  callOperator: "ユーザ",
  tenantAttributeViewer: "テナント情報閲覧者",
  tenantAttributeEditor: "テナント情報編集者",
};

const Info = () => {
  const navigate = useNavigate();
  const { tenantId } = useParams();

  const user = useSelector((state: RootState) => state.user.loggedInUser);
  const [tenantAttributes, setTenantAttributes] = useState<{
    [key: string]: string;
  }>({});
  const [newAttributeKey, setNewAttributeKey] = useState("");
  const [newAttributeValue, setNewAttributeValue] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [editingKey, setEditingKey] = useState<string | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deletingKey, setDeletingKey] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true); // ローディング状態を追加
  const [users, setUsers] = useState<
    {
      id: string;
      name: string;
      email: string;
      roles: string[];
      invisible: boolean;
    }[]
  >([]);

  const breadcrumbRefs: BreadcrumbRef[] = useMemo(
    () => [{ title: "テナント情報" }],
    []
  );

  const canEdit = user.permissions.includes("tenants.attributes.update");
  const canCreate = user.permissions.includes("tenants.attributes.create");

  useEffect(() => {
    if (!user.online) return;
    if (
      !user.permissions.includes("tenants.attributes.get") &&
      !user.permissions.includes("tenants.attributes.list")
    ) {
      navigate(`/${tenantId}/`);
    }
  }, [navigate, tenantId, user.online, user.permissions]);

  useEffect(() => {
    if (
      !user.permissions.includes("tenants.attributes.get") &&
      !user.permissions.includes("tenants.attributes.list")
    ) {
      return;
    }

    (async () => {
      const res = await request({
        path: "/tenants/current/attributes",
        httpMethod: "get",
      });

      if (res.result === "success" && res.data) {
        setTenantAttributes(res.data);
      } else {
        console.error(res.error);
      }
      setIsLoading(false); // データ取得完了時にローディングを無効化
    })();
  }, [navigate, tenantId, user.permissions]);

  useEffect(() => {
    (async () => {
      const res = await request({
        path: "/users",
        httpMethod: "get",
      });

      if (res.result === "success" && res.data) {
        setUsers(
          res.data.users.map((user) => ({
            id: user.id,
            name: user.name,
            email: user.email,
            roles: user.roles,
            invisible: user.invisible,
          }))
        );
      } else {
        console.error(res.error);
      }
    })();
  }, []);

  const upsertTenantAttributes = useCallback(
    async (key: string, value: string) => {
      setIsUpdating(true);
      try {
        const res = await request({
          path: "/tenants/current/attributes/{attributeKey}",
          httpMethod: "put",
          params: {
            paths: {
              attributeKey: key,
            },
            body: {
              value: value,
            },
          },
        });
        if (res.result === "success") {
          setTenantAttributes((prev) => ({
            ...prev,
            [key]: value,
          }));
        } else {
          console.error(res.error);
        }
      } finally {
        setIsUpdating(false);
      }
    },
    []
  );

  const deleteTenantAttribute = useCallback(async (key: string) => {
    setIsDeleting(true);
    try {
      const res = await request({
        path: "/tenants/current/attributes/{attributeKey}",
        httpMethod: "delete",
        params: {
          paths: {
            attributeKey: key,
          },
        },
      });
      if (res.result === "success") {
        setTenantAttributes((prev) => {
          const updated = { ...prev };
          delete updated[key];
          return updated;
        });
      } else {
        console.error(res.error);
      }
    } finally {
      setIsDeleting(false);
      setDeletingKey(null);
    }
  }, []);

  const handleAddAttribute = async () => {
    setIsAdding(true);
    try {
      await upsertTenantAttributes(newAttributeKey, newAttributeValue);
      setNewAttributeKey("");
      setNewAttributeValue("");
    } finally {
      setIsAdding(false);
    }
  };

  const filteredUsers = useMemo(
    () => users.filter((user) => !user.invisible),
    [users]
  );

  const sortedUsers = useMemo(
    () => filteredUsers.sort((a, b) => a.email.localeCompare(b.email)),
    [filteredUsers]
  );

  const sortedAttributes = useMemo(
    () =>
      Object.entries(tenantAttributes).sort(([keyA], [keyB]) =>
        keyA.localeCompare(keyB)
      ),
    [tenantAttributes]
  );

  if (
    !user.permissions.includes("tenants.attributes.get") &&
    !user.permissions.includes("tenants.attributes.list")
  ) {
    return null;
  }

  if (isLoading) {
    return (
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Box sx={{ mb: 2 }}>
          <DSBreadcrumbs breadcrumbRefs={breadcrumbRefs}></DSBreadcrumbs>
        </Box>
        <Box sx={{ mb: 4 }}>
          <List>
            {Array.from({ length: 5 }).map((_, index) => (
              <ListItem
                key={index}
                sx={{ borderBottom: "1px solid #ddd", gap: 2 }}
              >
                <Skeleton variant="text" width="30%" />
                <Skeleton variant="text" width="60%" />
              </ListItem>
            ))}
          </List>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Box sx={{ mb: 2 }}>
        <DSBreadcrumbs breadcrumbRefs={breadcrumbRefs}></DSBreadcrumbs>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          テナント属性
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>名前</TableCell>
                <TableCell>値</TableCell>
                {canEdit && <TableCell>操作</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedAttributes.map(([key, value]) => (
                <TableRow key={key}>
                  <TableCell sx={{ wordBreak: "break-word" }}>{key}</TableCell>
                  <TableCell sx={{ wordBreak: "break-word" }}>
                    {editingKey === key ? (
                      <TextField
                        label="値"
                        variant="outlined"
                        size="small"
                        value={value}
                        onChange={(e) =>
                          setTenantAttributes((prev) => ({
                            ...prev,
                            [key]: e.target.value,
                          }))
                        }
                        fullWidth
                      />
                    ) : value.startsWith("http") ? (
                      <Link href={value} target="_blank" rel="noopener">
                        {value}
                      </Link>
                    ) : (
                      value
                    )}
                  </TableCell>
                  {canEdit && (
                    <TableCell>
                      {editingKey === key ? (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            upsertTenantAttributes(key, tenantAttributes[key]);
                            setEditingKey(null);
                          }}
                          disabled={isUpdating}
                        >
                          {isUpdating ? <CircularProgress size={24} /> : "保存"}
                        </Button>
                      ) : (
                        <IconButton
                          color="primary"
                          onClick={() => setEditingKey(key)}
                        >
                          <Edit />
                        </IconButton>
                      )}
                      <IconButton
                        color="secondary"
                        onClick={() => setDeletingKey(key)}
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              ))}
              {canCreate && (
                <TableRow>
                  <TableCell>
                    <TextField
                      label="名前"
                      variant="outlined"
                      size="small"
                      value={newAttributeKey}
                      onChange={(e) => setNewAttributeKey(e.target.value)}
                      fullWidth
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      label="値"
                      variant="outlined"
                      size="small"
                      value={newAttributeValue}
                      onChange={(e) => setNewAttributeValue(e.target.value)}
                      fullWidth
                    />
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleAddAttribute}
                      disabled={isAdding}
                    >
                      {isAdding ? <CircularProgress size={24} /> : "追加"}
                    </Button>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          ユーザ一覧
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>メールアドレス</TableCell>
                <TableCell>名前</TableCell>
                <TableCell>ロール</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedUsers.map((user) => (
                <TableRow key={user.id}>
                  <TableCell align="left" sx={{ verticalAlign: "top" }}>
                    {user.email}
                  </TableCell>
                  <TableCell align="left" sx={{ verticalAlign: "top" }}>
                    {user.name}
                  </TableCell>
                  <TableCell>
                    {user.roles.map((role, index) => (
                      <Typography key={index}>
                        {ROLE_DISPLAY_NAMES[role] || role}
                      </Typography>
                    ))}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Dialog open={!!deletingKey} onClose={() => setDeletingKey(null)}>
        <DialogTitle>情報の削除</DialogTitle>
        <DialogContent>
          <DialogContentText>
            この情報を削除してもよろしいですか？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeletingKey(null)} disabled={isDeleting}>
            キャンセル
          </Button>
          <Button
            onClick={() => deletingKey && deleteTenantAttribute(deletingKey)}
            color="error"
            disabled={isDeleting}
          >
            {isDeleting ? <CircularProgress size={24} /> : "削除"}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Info;
